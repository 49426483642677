import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const OAuthComplete = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const error = params.get('error');
  const isErrorPath = location.pathname === '/oauth-error';
  const isSuccess = !error && !isErrorPath;

  useEffect(() => {
    // Notify parent window
    if (window.opener) {
      window.opener.postMessage({ 
        type: 'OAUTH_COMPLETE', 
        success: isSuccess,
        error: error
      }, '*');
    }

    // Close popup after showing message
    setTimeout(() => {
      window.close();
    }, 1800);
  }, [isSuccess, error]);

  let content;
  if (isSuccess) {
    content = (
      <>
        <CheckCircleIcon 
          sx={{ 
            fontSize: 48, 
            color: 'success.main',
            mb: 2 
          }} 
        />
        <Typography variant="h6" color="success.main" align="center">
          Successfully Connected!
        </Typography>
      </>
    );
  } else {
    let errorMessage;
    switch (error) {
      case 'callback_failed':
        errorMessage = 'Authentication callback failed. Please try again.';
        break;
      case 'invalid_state':
        errorMessage = 'Invalid authentication state. Please try again.';
        break;
      case 'invalid_token':
        errorMessage = 'Invalid authentication token. Please try again.';
        break;
      default:
        errorMessage = 'An error occurred during authentication. Please try again.';
    }

    content = (
      <>
        <ErrorIcon 
          sx={{ 
            fontSize: 48, 
            color: 'error.main',
            mb: 2 
          }} 
        />
        <Typography variant="h6" color="error.main" align="center">
          Connection Failed
        </Typography>
        <Typography 
          variant="body2" 
          color="error" 
          align="center"
          sx={{ maxWidth: '80%', mt: 1 }}
        >
          {errorMessage}
        </Typography>
      </>
    );
  }

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'background.default',
        p: 3
      }}
    >
      {content}
    </Box>
  );
};

export default OAuthComplete;