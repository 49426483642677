


// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// // import './LeadManagement.css';
// // import ViewLeadPopup from './ViewLeadPopup';
// // import EditLeadPopup from './EditLeadPopup';

// // function LeadManagement() {
// //   const [leads, setLeads] = useState([]);
// //   // const [intelligences, setIntelligences] = useState([]);
// //   // const [campaigns, setCampaigns] = useState([]);
// //   const [selectedLead, setSelectedLead] = useState(null);
// //   const [showViewPopup, setShowViewPopup] = useState(false);
// //   const [showEditPopup, setShowEditPopup] = useState(false);



// //   useEffect(() => {
// //     fetchLeads();
// //   }, []);

// //   const fetchLeads = async () => {
// //     try {
// //       const response = await axios.get('http://localhost:5000/leads');
// //       setLeads(response.data);
// //     } catch (error) {
// //       console.error('Error fetching leads:', error);
// //     }
// //   };

// //   const handleView = (lead) => {
// //     setSelectedLead(lead);
// //     setShowViewPopup(true);
// //   };

// //   const handleCloseViewPopup = () => {
// //     setShowViewPopup(false);
// //     setSelectedLead(null);
// //   };

// //   const handleEdit = (lead) => {
// //     setSelectedLead(lead);
// //     setShowEditPopup(true);
// //   };

// //   const handleCloseEditPopup = () => {
// //     setShowEditPopup(false);
// //     setSelectedLead(null);
// //   };

// //   const handleSaveEditedLead = (updatedLead) => {
// //     setLeads(leads.map(c => (c.email === updatedLead.email ? updatedLead : c)));
// //   };

// //   return (
// //     <div className="lead-management">
// //       <div className="header">
// //         <h1>Leads Management</h1>
// //         <div className="controls">
// //           <button className="sort-btn">↕ Add to campaign</button>
// //           <button className="filter-btn">◈ Search</button>
// //         </div>
// //       </div>
// //       <table className="leads-table">
// //         <thead>
// //           <tr>
// //             <th>LEAD NAME</th>
// //             <th>DESIGNATION</th>
// //             <th>EMAIL</th>
// //             <th>INDUSTRY TYPE</th>
// //             <th>ACTIONS</th>
// //           </tr>
// //         </thead>
// //         <tbody>
// //           {leads.map(lead => (
// //             <tr key={lead.email}>
// //               <td>{lead.first_name}</td>
// //               <td>{lead.designation}</td>
// //               <td>{lead.email}</td>
// //               <td>Digital marketing</td>
// //               <td className="actions-cell">
// //                 <button onClick={() => handleView(lead)} className="action-btn view-btn">
// //                   <span className="icon">👁️</span>
// //                 </button>
// //                 <button onClick={() => handleEdit(lead)} className="action-btn edit-btn">
// //                   <span className="icon">✏️</span>
// //                 </button>
// //               </td>
// //             </tr>
// //           ))}
// //         </tbody>
// //       </table>
// //       {showViewPopup && selectedLead && (
// //         <ViewLeadPopup
// //           lead={selectedLead}
// //           onClose={handleCloseViewPopup}
// //         />
// //       )}
// //       {showEditPopup && selectedLead && (
// //         <EditLeadPopup
// //           lead={selectedLead}
// //           onClose={handleCloseEditPopup}
// //           onSave={handleSaveEditedLead}
// //         />
// //       )}
// //     </div>
// //   );
// // }

// // export default LeadManagement;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './LeadManagement.css';
// import ViewLeadPopup from './ViewLeadPopup';
// import EditLeadPopup from './EditLeadPopup';

// function LeadManagement() {
//   const [leads, setLeads] = useState([]);
//   const [selectedLead, setSelectedLead] = useState(null);
//   const [showViewPopup, setShowViewPopup] = useState(false);
//   const [showEditPopup, setShowEditPopup] = useState(false);
//   const [isAddingToCampaign, setIsAddingToCampaign] = useState(false);
//   const [selectedCampaign, setSelectedCampaign] = useState('');
//   const [editingNote, setEditingNote] = useState(null);
//   const [admins, setAdmins] = useState([]);

//   useEffect(() => {
//     fetchLeads();
//     fetchAdmins(); // New function to fetch admins
//   }, []);

//   const fetchAdmins = async () => {
//     try {
//       const response = await axios.get('http://localhost:5000/admins');
//       setAdmins(response.data);
//     } catch (error) {
//       console.error('Error fetching admins:', error);
//     }
//   };

//   const fetchLeads = async () => {
//     try {
//       const response = await axios.get('http://localhost:5000/leads');
//       setLeads(response.data);
//     } catch (error) {
//       console.error('Error fetching leads:', error);
//     }
//   };

//   const handleView = (lead) => {
//     setSelectedLead(lead);
//     setShowViewPopup(true);
//   };

//   const handleCloseViewPopup = () => {
//     setShowViewPopup(false);
//     setSelectedLead(null);
//   };

//   const handleEdit = (lead) => {
//     setSelectedLead(lead);
//     setShowEditPopup(true);
//   };

//   const handleCloseEditPopup = () => {
//     setShowEditPopup(false);
//     setSelectedLead(null);
//   };

//   const handleSaveEditedLead = (updatedLead) => {
//     setLeads(leads.map(c => (c.email === updatedLead.email ? updatedLead : c)));
//   };

//   const toggleAddToCampaign = () => {
//     setIsAddingToCampaign(!isAddingToCampaign);
//     if (!isAddingToCampaign) {
//       setSelectedCampaign('');
//     }
//   };

//   const handleAddToCampaign = async (leadEmail) => {
//     if (!selectedCampaign) return;

//     try {
//       // This is where you'd make an API call to add the lead to the campaign
//       // For now, we'll just update the local state
//       const updatedLeads = leads.map(lead => 
//         lead.email === leadEmail 
//           ? { ...lead, campaign: selectedCampaign } 
//           : lead
//       );
//       setLeads(updatedLeads);
//     } catch (error) {
//       console.error('Error adding lead to campaign:', error);
//     }
//   };

//   const handleNoteChange = async (leadEmail, newNote) => {
//     try {
//       const response = await axios.put(`http://localhost:5000/leads/${leadEmail}/notes`, { notes: newNote });
//       if (response.status === 200) {
//         setLeads(leads.map(lead => 
//           lead.email === leadEmail ? { ...lead, notes: newNote } : lead
//         ));
//       }
//     } catch (error) {
//       console.error('Error updating note:', error);
//     }
//     setEditingNote(null);
//   };

//   const handleOwnerChange = async (leadEmail, newOwner) => {
//     try {
//       const response = await axios.put(`http://localhost:5000/leads/${leadEmail}/owner`, { owner: newOwner });
//       if (response.status === 200) {
//         setLeads(leads.map(lead => 
//           lead.email === leadEmail ? { ...lead, owner: newOwner } : lead
//         ));
//       }
//     } catch (error) {
//       console.error('Error updating owner:', error);
//     }
//   };

//   return (
//     <div className="lead-management">
//       <div className="header">
//         <h1>Leads Management</h1>
//         <div className="controls">
//           <button className="sort-btn" onClick={toggleAddToCampaign}>
//             {isAddingToCampaign ? 'Cancel' : '↕ Add to campaign'}
//           </button>
//           <button className="filter-btn">◈ Search</button>
//         </div>
//       </div>
//       {isAddingToCampaign && (
//         <div className="campaign-select">
//         <select 
//           value={selectedCampaign} 
//           onChange={(e) => setSelectedCampaign(e.target.value)}
//         >
//           <option value="">Select a campaign</option>
//           {/* Add campaign options here */}
//           <option value="campaign1">Campaign 1</option>
//           <option value="campaign2">Campaign 2</option>
//         </select>
//       </div>
//       )}
//       <table className="leads-table">
//         <thead>
//           <tr>
//             <th>LEAD NAME</th>
//             <th>DESIGNATION</th>
//             <th>EMAIL</th>
//             {/* <th>COMPANY NAME</th> */}
//             {/* <th>WEBSITE</th> */}
//             <th>INDUSTRY TYPE</th>
//             <th>NOTES</th>
//             <th>ACTIONS</th>
//             <th>OWNERED BY</th>
//             {isAddingToCampaign && <th>ADD TO CAMPAIGN</th>}
//           </tr>
//         </thead>
//         <tbody>
//           {leads.map(lead => (
//             <tr key={lead.email}>
//               <td>{lead.first_name} {lead.last_name}</td>
//               <td>{lead.designation}</td>
//               <td>{lead.email}</td>
//               {/* <td>{lead.company_name}</td>
//               <td>{lead.website}</td> */}
//               <td>{lead.industry_type}</td>
//               <td>
//                 {editingNote === lead.email ? (
//                   <input
//                     type="text"
//                     value={lead.notes || ''}
//                     onChange={(e) => {
//                       const updatedLeads = leads.map(l => 
//                         l.email === lead.email ? { ...l, notes: e.target.value } : l
//                       );
//                       setLeads(updatedLeads);
//                     }}
//                     onBlur={() => handleNoteChange(lead.email, lead.notes)}
//                     autoFocus
//                   />
//                 ) : (
//                   <span onClick={() => setEditingNote(lead.email)}>
//                     {lead.notes || 'Click to add notes'}
//                   </span>
//                 )}
//               </td>
//               <td className="actions-cell">
//                 <button onClick={() => handleView(lead)} className="action-btn view-btn">
//                   <span className="icon">👁️</span>
//                 </button>
//                 <button onClick={() => handleEdit(lead)} className="action-btn edit-btn">
//                   <span className="icon">✏️</span>
//                 </button>
//               </td>
//               <td>
//                 {lead.owner ? (
//                   lead.owner
//                 ) : (
//                   <select
//                     value=""
//                     onChange={(e) => handleOwnerChange(lead.email, e.target.value)}
//                   >
//                     <option value="">Select an owner</option>
//                     {admins.map(admin => (
//                       <option key={admin} value={admin}>{admin}</option>
//                     ))}
//                   </select>
//                 )}
//               </td>
//               {isAddingToCampaign && (
//                 <td>
//                   {lead.campaign ? (
//                     <button 
//                       className="add-to-campaign-btn added"
//                       disabled
//                     >
//                       Added
//                     </button>
//                   ) : (
//                     <button 
//                       className="add-to-campaign-btn"
//                       onClick={() => handleAddToCampaign(lead.email)}
//                       disabled={!selectedCampaign}
//                     >
//                       Add
//                     </button>
//                   )}
//                 </td>
//               )}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       {showViewPopup && selectedLead && (
//         <ViewLeadPopup
//           lead={selectedLead}
//           onClose={handleCloseViewPopup}
//         />
//       )}
//       {showEditPopup && selectedLead && (
//         <EditLeadPopup
//           lead={selectedLead}
//           onClose={handleCloseEditPopup}
//           onSave={handleSaveEditedLead}
//         />
//       )}
//     </div>
//   );
// }

// export default LeadManagement;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LeadManagement.css';
import { useNavigate } from 'react-router-dom';
import ViewLeadPopup from './ViewLeadPopup';
import EditLeadPopup from './EditLeadPopup';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Sidebar from '../MainPage/Sidebar';

function LeadManagement() {
  const [leads, setLeads] = useState([]);
  const navigate = useNavigate();
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [showViewPopup, setShowViewPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [isAddingToCampaign, setIsAddingToCampaign] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [admins, setAdmins] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [editingNote, setEditingNote] = useState(null);
  const [industryTypes, setIndustryTypes] = useState([]);
 

  const [filters, setFilters] = useState({
    designation: '',
    owner: '',
    name: ''
  });

  useEffect(() => {
    fetchLeads();
    fetchAdmins();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [leads, filters]);

  const fetchLeads = async () => {
    try {
      const response = await axios.get('https://hypelead-backend.onrender.com/leads');
      setLeads(response.data);
      const uniqueDesignations = [...new Set(response.data.map(lead => lead.designation))];
      setDesignations(uniqueDesignations);
      const uniqueIndustryTypes = [...new Set(response.data.map(lead => lead.industry_type))];
      setIndustryTypes(uniqueIndustryTypes);
    } catch (error) {
      console.error('Error fetching leads:', error);
    }
  };

  const fetchAdmins = async () => {
    try {
      const response = await axios.get('https://hypelead-backend.onrender.com/admins');
      setAdmins(response.data);
    } catch (error) {
      console.error('Error fetching admins:', error);
    }
  };

  const handleView = (lead) => {
    setSelectedLead(lead);
    // setShowViewPopup(true);
    // navigate(`/lead/${lead.id}`);
    navigate(`/profile` ,{ state: { leadname: lead } });
  };

  const handleCloseViewPopup = () => {
    setShowViewPopup(false);
    setSelectedLead(null);
  };

  const handleEdit = (lead) => {
    setSelectedLead(lead);
    setShowEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setShowEditPopup(false);
    setSelectedLead(null);
  };

  const handleSaveEditedLead = (updatedLead) => {
    setLeads(leads.map(lead => (lead.email === updatedLead.email ? updatedLead : lead)));
  };

  const toggleAddToCampaign = () => {
    setIsAddingToCampaign(!isAddingToCampaign);
    if (!isAddingToCampaign) {
      setSelectedCampaign('');
    }
  };

  const handleAddToCampaign = async (leadEmail) => {
    if (!selectedCampaign) return;

    try {
      // This is where you'd make an API call to add the lead to the campaign
      // For now, we'll just update the local state
      const updatedLeads = leads.map(lead => 
        lead.email === leadEmail 
          ? { ...lead, campaign: selectedCampaign } 
          : lead
      );
      setLeads(updatedLeads);
    } catch (error) {
      console.error('Error adding lead to campaign:', error);
    }
  };

  const handleOwnerChange = async (leadEmail, newOwner) => {
    try {
      const response = await axios.put(`https://hypelead-backend.onrender.com/leads/${leadEmail}/owner`, { owner: newOwner });
      if (response.status === 200) {
        setLeads(leads.map(lead => 
          lead.email === leadEmail ? { ...lead, owner: newOwner } : lead
        ));
      }
    } catch (error) {
      console.error('Error updating owner:', error);
    }
  };

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));
  };

  const applyFilters = () => {
    let filtered = leads;

    if (filters.designation) {
      filtered = filtered.filter(lead => lead.designation === filters.designation);
    }
    if (filters.owner) {
      filtered = filtered.filter(lead => lead.owner === filters.owner);
    }
    if (filters.name) {
      const lowercaseName = filters.name.toLowerCase();
      filtered = filtered.filter(lead => 
        lead.first_name.toLowerCase().includes(lowercaseName) ||
        lead.last_name.toLowerCase().includes(lowercaseName)
      );
    }
    if (filters.industryType) {
      filtered = filtered.filter(lead => lead.industry_type === filters.industryType);
    }

    setFilteredLeads(filtered);
  };

  const handleNoteChange = async (leadEmail, newNote) => {
        try {
          const response = await axios.put(`https://hypelead-backend.onrender.com/leads/${leadEmail}/notes`, { notes: newNote });
          if (response.status === 200) {
            setLeads(leads.map(lead => 
              lead.email === leadEmail ? { ...lead, notes: newNote } : lead
            ));
          }
        } catch (error) {
          console.error('Error updating note:', error);
        }
        setEditingNote(null);
      };

  return (
    <div className="lead-management">
      <Sidebar/>
      <div className="headerlead">
        <h1>Leads Management</h1>
        {/* <div className="controls">
          <button className="sort-btn" onClick={toggleAddToCampaign}>
            {isAddingToCampaign ? 'Cancel' : '↕ Add to campaign'}
          </button>
          <button className="filter-btn">◈ Search</button>
        </div> */}
      </div>

      <div className="filter-section">
        <select
          value={filters.designation}
          onChange={(e) => handleFilterChange('designation', e.target.value)}
        >
          <option value="">All Designations</option>
          {designations.map(designation => (
            <option key={designation} value={designation}>{designation}</option>
          ))}
        </select>
        <select
          value={filters.industryType}
          onChange={(e) => handleFilterChange('industryType', e.target.value)}
        >
          <option value="">All Industry Types</option>
          {industryTypes.map(industry => (
            <option key={industry} value={industry}>{industry}</option>
          ))}
        </select>

        <select
          value={filters.owner}
          onChange={(e) => handleFilterChange('owner', e.target.value)}
        >
          <option value="">All Owners</option>
          {admins.map(admin => (
            <option key={admin} value={admin}>{admin}</option>
          ))}
        </select>

        <input
          type="text"
          placeholder="Search by name"
          value={filters.name}
          onChange={(e) => handleFilterChange('name', e.target.value)}
        />
      </div>

      {isAddingToCampaign && (
        <div className="campaign-select">
          <select 
            value={selectedCampaign} 
            onChange={(e) => setSelectedCampaign(e.target.value)}
          >
            <option value="">Select a campaign</option>
            <option value="campaign1">Campaign 1</option>
            <option value="campaign2">Campaign 2</option>
          </select>
        </div>
      )}

      <table className="leads-table">
        <thead>
          <tr>
            <th>LEAD NAME</th>
            <th>DESIGNATION</th>
            <th>EMAIL</th>
            <th>INDUSTRY TYPE</th>
            <th>NOTES</th>
            <th>ACTIONS</th>
            <th>OWNED BY</th>
            {isAddingToCampaign && <th>ADD TO CAMPAIGN</th>}
          </tr>
        </thead>
        <tbody>
          {filteredLeads.map(lead => (
            <tr key={lead.email}>
              <td>{`${lead.first_name} ${lead.last_name}`}</td>
              <td>{lead.designation}</td>
              <td>{lead.email}</td>
              <td>{lead.industry_type}</td>
              {/* <td>{lead.notes || 'No notes'}</td> */}
              <td>
                 {editingNote === lead.email ? (
                  <input
                    type="text"
                    value={lead.notes || ''}
                    onChange={(e) => {
                      const updatedLeads = leads.map(l => 
                        l.email === lead.email ? { ...l, notes: e.target.value } : l
                      );
                      setLeads(updatedLeads);
                    }}
                    onBlur={() => handleNoteChange(lead.email, lead.notes)}
                    autoFocus
                  />
                ) : (
                  <span onClick={() => setEditingNote(lead.email)}>
                    {lead.notes || 'Click to add notes'}
                  </span>
                )}
              </td>
              <td className="actions-cell">
                <button onClick={() => handleView(lead.email)} className="action-btn view-btn">
                <span className="fas fa-eye"></span>
                </button>
                <button onClick={() => handleEdit(lead)} className="action-btn edit-btn">
                <span className="fas fa-edit"></span>
                </button>
              </td>
              <td>
                {lead.owner ? (
                  lead.owner
                ) : (
                  <select
                    value=""
                    onChange={(e) => handleOwnerChange(lead.email, e.target.value)}
                  >
                    <option value="">Select an owner</option>
                    {admins.map(admin => (
                      <option key={admin} value={admin}>{admin}</option>
                    ))}
                  </select>
                )}
              </td>
              {isAddingToCampaign && (
                <td>
                  {lead.campaign ? (
                    <button 
                      className="add-to-campaign-btn added"
                      disabled
                    >
                      Added
                    </button>
                  ) : (
                    <button 
                      className="add-to-campaign-btn"
                      onClick={() => handleAddToCampaign(lead.email)}
                      disabled={!selectedCampaign}
                    >
                      Add
                    </button>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {showViewPopup && selectedLead && (
        <ViewLeadPopup
          lead={selectedLead}
          onClose={handleCloseViewPopup}
        />
      )}
      {showEditPopup && selectedLead && (
        <EditLeadPopup
          lead={selectedLead}
          onClose={handleCloseEditPopup}
          onSave={handleSaveEditedLead}
        />
      )}
    </div>
  );
}

export default LeadManagement;