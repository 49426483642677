import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './AnalyticsPage.css';
import doc from '../doc.png';
import edit from '../edit.png';


const AddPromptModal = ({ open, onClose, newPromptContent, setNewPromptContent, handleAddPrompt }) => (
  <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      style: {
        width: '100%',
        height: '90%',
        padding: '30px',
        borderRadius: '30px',
      },
    }}
    className="dialog"
  >
    <DialogTitle className="dialog-title">
    <h6 className="add-your-own-prompt" style={{ display: 'flex', alignItems: 'center' }}>
  <img src={edit} alt="edit" style={{ marginRight: 8 }} /> Add Your Own Prompt</h6>
  
      <IconButton
        aria-label="close"
        onClick={onClose}
        style={{
          position: 'absolute',
          right: 50,
          top: 40,
          color: 'white',
          width:30,
          height:30,
          backgroundColor:'#5D5FEF',
          borderRadius: '10px',
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent className="dialog-content">
      <TextField
        value={newPromptContent}
        onChange={(e) => setNewPromptContent(e.target.value)}
        placeholder="Enter your chat"
        multiline
        rows={25}
        fullWidth
        className="dialog-textfield"
      />
    </DialogContent>
    <DialogActions className="dialog-actions">
      <Button
        variant="contained"
        onClick={() => {
          handleAddPrompt();
          onClose(); // Close the popup after adding
        }}
        disabled={!newPromptContent.trim()}
        className="dialog-add-button"
        startIcon={<img src={doc} alt="doc" />}
      >
        Add Prompt
      </Button>
    </DialogActions>
  </Dialog>
);

export default AddPromptModal;