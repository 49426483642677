import React from 'react';
import './ViewCampaignPopup.css';

function ViewCampaignPopup({ campaign, onClose }) {
  return (
    <div className="popup-over">
      <div className="popup-text">
        <h2>Campaign Details</h2>
        <p><strong>Name:</strong> {campaign.campaign_name}</p>
        <p><strong>Type:</strong> {campaign.campaign_type}</p>
        <p><strong>Regions:</strong> {campaign.campaign_region}</p>
        <p><strong>Lead Goal:</strong> {campaign.lead_goals}</p>
        <button onClick={onClose} className="close-btn">Close</button>
      </div>
    </div>
  );
}

export default ViewCampaignPopup;
