import React from 'react';
import './ViewLeadManagementPopup.css';

function ViewLeadManagementPopup({ lead, onClose }) {
  return (
    <div className="popup-over">
      <div className="popup-text">
        <h2>Lead Details</h2>
        <p><strong>Name:</strong> {lead.first_name}</p>
        <p><strong>Designation:</strong> {lead.designation}</p>
        <p><strong>Email:</strong> {lead.email}</p>
        <p><strong>Company Name:</strong> {lead.company_name}</p>
        <button onClick={onClose} className="close-btn">Close</button>
      </div>
    </div>
  );
}

export default ViewLeadManagementPopup;

