import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Sidebar.css';
import logo from '../../Untitled-design-22.png'; // Adjust the path as needed
import SwitcherButton from './SwitcherButton'; // Import the new component
import '@fortawesome/fontawesome-free/css/all.min.css';


const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activePage, setActivePage] = useState(location.pathname);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleNavigation = (path) => {
    setActivePage(path);
    navigate(path);
    if (window.innerWidth <= 768) {
      setIsSidebarOpen(false); // Close sidebar on mobile after navigation
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('userDetails');
    navigate('/');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const topMenuItems = [

  { path: '/campaigns', icon: 'fas fa-exchange-alt', label: 'All Campaigns' },
  { path: '/main', icon: 'fas fa-download', label: 'Import Leads' },
  { path: '/leads', icon: 'fas fa-tasks', label: 'Leads Management' },
  { path: '/campaign-analytics', icon: 'fas fa-chart-bar', label: 'Campaign Analytics' },
  { path: '/email', icon: 'fas fa-envelope', label: 'Email Automation' },
  ];

  const middleMenuItems = [
    { path: '/followup', icon: 'fa-solid fa-hand-point-right', label: 'Follow UP' },
    { path: '/calendar', icon: 'fa-solid fa-calendar-days', label: 'Calendar' },
    { path: '/inbox', icon: 'fa-solid fa-inbox', label: 'Inbox' },
  ];

  const bottomMenuItems = [
    { path: '/admin', icon: 'fa-solid fa-user', label: 'Account Page' },
  ];

  const link1 = { path: 'https://test3.webgeekinfo.com/', label: 'Main Site' };
  const link2 = { path: 'https://test3.webgeekinfo.com/WP-admin/', label: 'Admin Dashboard' };

  return (
    <div>
      {/* Sidebar Toggle Button for Mobile */}
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        ☰
      </button>
      
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="logo-container">
          <img src={logo} alt="Company Logo" className="logo" />
        </div>
        <SwitcherButton link1={link1} link2={link2} /> {/* Add the switcher button right after the logo */}
        <div className="menu-container">
          <nav>
          <ul className="top-menu">
  {topMenuItems.map((item) => (
    <li key={item.path}>
      <button
        onClick={() => handleNavigation(item.path)}
        className={`nav-button ${activePage === item.path ? 'active' : ''}`}
      >
        <i className={item.icon}></i> <span>{item.label}</span>
      </button>
    </li>
  ))}
</ul>

            <div className="menu-divider"></div>
            <ul className="middle-menu">
              {middleMenuItems.map((item) => (
                <li key={item.path}>
                  <button
                    onClick={() => handleNavigation(item.path)}
                    className={`nav-button ${activePage === item.path ? 'active' : ''}`}
                  >
                    <i className={item.icon}></i> <span>{item.label}</span>
                  </button>
                </li>
              ))}
            </ul>
            <div className="menu-divider"></div>
            <ul className="bottom-menu">
              {bottomMenuItems.map((item) => (
                <li key={item.path}>
                  <button
                    onClick={() => handleNavigation(item.path)}
                    className={`nav-button ${activePage === item.path ? 'active' : ''}`}
                  >
                    <i className={item.icon}></i> <span>{item.label}</span>
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="logout-container">
          <button onClick={handleLogout} className="logout-button">
          <span className="fa-solid fa-right-from-bracket"></span> Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
