import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Box, Typography, Button, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { useClerk, UserButton, useUser } from "@clerk/clerk-react";
import './HomePage.css';
import HomeSidebar from './HomeSidebar'; // Adjust the path according to your file structure
import book from '../book.png';
import tab from '../tab.png';


const PageContainer = styled(Box)({
  display: 'flex',
  height: '100vh',
  backgroundColor: '#F0F4FF',
  // Add class name
  className: 'page-container',
});

const MainContent = styled(Box)(({ collapsed }) => ({
  flexGrow: 1,
  padding: '20px 40px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  transition: 'margin 0.3s ease',
  marginLeft: collapsed ? 0 : 0,
  alignContent: 'center',
  alignSelf: 'center',
  // Add class name
  className: 'main-content',
}));

function HomePage() {
  const [activeTab, setActiveTab] = useState('analytics');
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const { signOut } = useClerk();
  const { user } = useUser();

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSignOut = () => {
    signOut().then(() => {
      localStorage.removeItem('userEmail');
      localStorage.removeItem('token');
      navigate('/login');
    });
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <PageContainer className="page-container">
      {/* Replace the Sidebar component with HomeSidebar */}
      <HomeSidebar collapsed={collapsed} toggleSidebar={toggleSidebar} handleNavigation={handleNavigation} handleSignOut={handleSignOut} />

      <MainContent collapsed={collapsed} className="main-content">
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 4 }} className="top-bar">
          <Typography variant="body2" sx={{ mr: 2 }} className="privacy-policy">Privacy Policy</Typography>
          <Typography variant="body2" className="terms-conditions">Terms & Conditions</Typography>
        </Box>
        <Box className="content-box">
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }} className="title">UNLOCK THE POWER OF AI</Typography>
          <Typography variant="subtitle1" gutterBottom className="subtitle">
            Revolutionize your marketing with Hype AI.
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ mb: 4 }} className="description">
            Seamlessly integrate with various marketing platforms
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, mb: 4 }} className="button-group">
          <Button
            variant="contained"
            onClick={() => handleNavigation('/analytics')}
            className="analytics-button"
          >
            <Box display="flex" flexDirection="column" alignItems="center">
               {<img src={tab} alt="doc" />}
              <Typography variant="button" sx={{ mt: 0.5 }}>
                Analytics
              </Typography>
            </Box>
          </Button>
          <Button
          variant="contained"
          onClick={() => handleNavigation('/main')}
          sx={{
            bgcolor: '#10B981',
            '&:hover': { bgcolor: '#059669' },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingY: 1
          }}
          className="lead-gen-button"
        >
          {<img src={book} alt="doc" />}
          <Typography variant="button" sx={{ mt: 0.5 }}>
            Lead Gen
          </Typography>
        </Button>
          </Box>
          <Typography variant="body2" className="footer-text">
              New features are being introduced twice a week!<br /> 
              Stay up-to-date by checking the change log here.
            </Typography>
        </Box>
      </MainContent>
    </PageContainer>
  );
}

export default HomePage;