import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import { useClerk, useUser } from "@clerk/clerk-react";
import './ManageSubscriptionPage.css';
import HomeSidebar from './SubscriptionSidebar';
import right from '../right.png';

const PageContainer = styled(Box)({
  display: 'flex',
  height: '100vh',
  backgroundColor: '#F0F4FF',
});

const MainContent = styled(Box)(({ collapsed }) => ({
  flexGrow: 1,
  padding: '20px 20px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  transition: 'margin 0.3s ease',
  marginLeft: collapsed ? 0 : 0,
  alignContent: 'center',
  alignSelf: 'center',
}));

// PricingBox Component
const PricingBox = ({ title, subtitle, price, features, storage, emails }) => (
  <Box
    className="pricing-box"
    sx={{
      backgroundColor: '#f5f7ff',
      borderRadius: '30px',
      padding: '20px',
      width: '350px',
      textAlign: 'center',
      marginBottom: '15px',
      boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
    }}
  >
    <Typography variant="h6" sx={{ color: '#5a4fb0', fontWeight: 'bold' }} className="pricing-title">{title}</Typography>
    <Typography variant="body2" sx={{ color: '#7a7a7a' }} className="pricing-subtitle">{subtitle}</Typography>
    <Typography variant="h3" sx={{ color: '#5a4fb0', fontWeight: 'bold', margin: '10px 0' }} className="pricing-price">
      ${price}
    </Typography>
    <Typography variant="body2" sx={{ color: '#7a7a7a', marginBottom: '10px' }} className="pricing-period">Per Month</Typography>
    <List className="pricing-features">
      {features.map((feature, index) => (
        <ListItem key={index} className="pricing-feature">
          <ListItemIcon className="feature-icon">
            <CheckIcon sx={{ color: '#5a4fb0' }} />
          </ListItemIcon>
          <ListItemText primary={feature} />
        </ListItem>
      ))}
    </List>
    <Typography variant="body2" sx={{ color: '#7a7a7a', marginTop: '0px' }} className="pricing-storage">
      Storage: {storage} | Emails: {emails}
    </Typography>
    <Button
      variant="outlined"
      className="pricing-button"
      sx={{
        color: '#5a4fb0',
        borderColor: '#5a4fb0',
        fontWeight: 'bold',
        marginTop: '10px',
        padding: '10px 20px',
        borderRadius: '25px',
      }}
    >
      Get Started
    </Button>
  </Box>
);

// SubscriptionPlans Component
const SubscriptionPlans = () => {
  const plans = [
    {
      title: 'Growth',
      subtitle: 'For Small Agencies',
      price: '199',
      features: [
        '5 Team Members',
        '10 Client Sites',
        '200 Leads/Month',
        '15 Email Campaigns',
        '10h AI Analysis',
        '3 Platforms',
      ],
      storage: '1GB',
      emails: '20K/Mo',
    },
    {
      title: 'Scale',
      subtitle: 'For Mid-Size Agencies',
      price: '499',
      features: [
        '15 Team Members',
        '25 Client Sites',
        '1000 Leads/Month',
        '40 Email Campaigns',
        '25h AI Analysis',
        '6 Platforms',
      ],
      storage: '2GB',
      emails: '60K/Mo',
    },
    {
      title: 'Enterprise                            ',
      subtitle: 'For Large Agencies',
      price: '999',
      features: [
        'Unlimited Teams',
        'Unlimited Sites',
        'Unlimited Leads',
        'Unlimited Emails',
        'Unlimited AI',
        'All Platforms',
      ],
      storage: '5GB+',
      emails: '150K/Mo',
    },
    // Add more plans here
  ];

  return (
    <Box className="subscription-plans content-box">
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }} className="title">
        Subscription Plans
      </Typography>
      <Button
      variant="outlined"
      className="monthly-button"
      sx={{
        color: '#ffffff',
        borderColor: '#ffffff',
        fontWeight: 'bold',
        marginBottom: '20px',
        padding: '10px 20px',
        borderRadius: '25px',
        backgroundColor: '#5D5FEF',
      }}
    >
      Monthly
    </Button>
    <Button
      variant="outlined"
      className="yearly-button"
      sx={{
        color: '#5D5FEF',
        borderColor: '#5D5FEF',
        fontWeight: 'bold',
        marginBottom: '20px',
        marginLeft: '15px',
        padding: '10px 20px',
        borderRadius: '25px',
      }}
    >
      Yearly
    </Button>
      <Box sx={{ display: 'flex', gap: '40px' }} className="plans-container">
        {plans.map((plan, index) => (
          <PricingBox key={index} {...plan} />
        ))}
      </Box>
      <div className="features-bar">
        <div className="feature" style={{ display: 'flex', alignItems: 'center' }} >
            <img
              src={right}
              alt="right"
              style={{ width: '18px', height: '18px' , marginRight: '5px' }}
            />
            <p>SSL Security</p>
        </div>
        <div className="feature" style={{ display: 'flex', alignItems: 'center' }} >
            <img
              src={right}
              alt="right"
              style={{ width: '18px', height: '18px' , marginRight: '5px' }}
            />
            <p>Daily Backups</p>
        </div>
        <div className="feature" style={{ display: 'flex', alignItems: 'center' }} >
            <img
              src={right}
              alt="right"
              style={{ width: '18px', height: '18px', marginRight: '5px' }}
            />
            <p>99.9% Uptime</p>
        </div>
        <div className="feature" style={{ display: 'flex', alignItems: 'center' }} >
            <img
              src={right}
              alt="right"
              style={{ width: '18px', height: '18px' , marginRight: '5px' }}
            />
            <p>Training Resources</p>
        </div>
      </div>
    </Box>
  );
};

function HomePage() {
  const [activeTab, setActiveTab] = useState('analytics');
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const { signOut } = useClerk();
  const { user } = useUser();

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSignOut = () => {
    signOut().then(() => {
      localStorage.removeItem('userEmail');
      localStorage.removeItem('token');
      navigate('/login');
    });
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <PageContainer className="page-container">
      <HomeSidebar
        collapsed={collapsed}
        toggleSidebar={toggleSidebar}
        handleNavigation={handleNavigation}
        handleSignOut={handleSignOut}
        className="home-sidebar"
      />

      <MainContent collapsed={collapsed} className="main-content">
        <SubscriptionPlans />
      </MainContent>
    </PageContainer>
  );
}

export default HomePage;