import React from 'react';
import './InfoSection.css';

const InfoIcon = () => (
  <svg className="info-icon" viewBox="0 0 24 24" width="24" height="24">
    <circle cx="12" cy="12" r="10" fill="#3498db" />
    <text x="12" y="17" fontSize="16" fontWeight="bold" fill="white" textAnchor="middle">i</text>
  </svg>
);

const InfoSection = () => {
  return (
    <div className="info-section">
      <InfoIcon />
      <div className="info-content">
        <p>To conduct a proper profile analysis and generate intelligence, ensure that all uploaded files contain the same columns as follows: Name, Designation, Email Address, LinkedIn URL</p>
        <p><strong>Generate Intelligence:</strong> Click the "Generate Intelligence" button to process the uploaded file.</p>
        <p><strong>Save Leads:</strong> Once intelligence is generated, the "Save Leads" button will be enabled. Select a campaign from the dropdown or choose "Leave Unassigned" and click "Save Leads" to save the leads.</p>
      </div>
    </div>
  );
};

export default InfoSection;