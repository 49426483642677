import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './EditCampaignPopup.css';

function EditCampaignPopup({ campaign, onClose, onSave }) {
  const [campaignDetails, setCampaignDetails] = useState(campaign);

  useEffect(() => {
    setCampaignDetails(campaign);
  }, [campaign]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCampaignDetails(prevState => ({
      ...prevState, 
      [name]: value
    }));
  };

  const handleSave = () => {
    axios.put(`https://hypelead-backend.onrender.com/campaignstable/${campaignDetails.campaign_name}`, campaignDetails)
      .then(response => {
        onSave(response.data);
        onClose();
      })
      .catch(error => console.error('Error updating campaign:', error));
  };

  return (
    <div className="edit-popup">
      <div className="edit-popup-content">
        <h2>Edit Campaign</h2>
        <form>
          <label>
            Campaign Name:
            <input type="text" name="campaign_name" value={campaignDetails.campaign_name} onChange={handleChange} />
          </label>
          <label>
            Type:
            <input type="text" name="campaign_type" value={campaignDetails.campaign_type} onChange={handleChange} />
          </label>
          <label>
            Regions:
            <input type="text" name="campaign_region" value={campaignDetails.campaign_region} onChange={handleChange} />
          </label>
          <label>
            Lead Goal:
            <input type="text" name="lead_goals" value={campaignDetails.lead_goals} onChange={handleChange} />
          </label>
          <button type="button" onClick={handleSave}>Save</button>
          <button type="button" onClick={onClose}>Cancel</button>
        </form>
      </div>
    </div>
  );
}

export default EditCampaignPopup;
