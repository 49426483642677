

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './InfoActionContainer.css';

function InfoActionContainer({ uploadTime, file, campaign, setCampaign, isUploaded }) {
  const [campaigns, setCampaigns] = useState([]);
  const [isGenerateEnabled, setIsGenerateEnabled] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  useEffect(() => {
    fetchCampaigns();
  }, []);

  useEffect(() => {
    setIsGenerateEnabled(isUploaded && campaign !== '');
  }, [isUploaded, campaign]);

  const fetchCampaigns = async () => {
    try {
      const response = await axios.get('https://hypelead-backend.onrender.com/campaigns');
      setCampaigns(response.data);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  const handleGenerateIntelligence = async () => {
    if (!file) {
      console.error('No file selected');
      return;
    }

    setIsGenerating(true);
    setIsSaveEnabled(false);

    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('campaign', campaign);

      const response = await axios.post('https://hypelead-backend.onrender.com/generate-intelligence', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log('Intelligence generated:', response.data);
      setIsSaveEnabled(true);
      // You can update the UI or state here based on the response
    } catch (error) {
      console.error('Error generating intelligence:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSaveLeads = async () => {
    if (!file) {
        console.error('No file selected');
        return;
      }

      setIsSaving(true);
      // setIsSaveEnabled(false);
  
      try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('campaign', campaign);
  
        const response = await axios.post('https://hypelead-backend.onrender.com/save_leads', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
  
        console.log('lead_scores generated:', response.data);
      } catch (error) {
        console.error('Error generating lead scores:', error);
      } finally {
        setIsSaving(false);
      }
    };

  return (
    <div className="info-action-container">
      <div className="column created-at">
        <span className="label">CREATED AT</span>
        <span className="value">{uploadTime || 'yyyy/mm/dd'}</span>
      </div>
      <div className="column file">
        <span className="label">FILE</span>
        <span className="value">{file ? file.name : 'No file selected'}</span>
        <span className="value upload-status">{file ? 'Upload completed' : ''}</span>
      </div>
      <div className="column campaign">
        <span className="label">CAMPAIGN</span>
        <select 
          value={campaign} 
          onChange={(e) => setCampaign(e.target.value)}
          className="campaign-select"
        >
          <option value="">Unselected</option>
          {campaigns.map((camp) => (
            <option key={camp.campaign_name} value={camp.campaign_name}>
              {camp.campaign_name}
            </option>
          ))}
        </select>
      </div>
      <div className="column generate">
        <button 
          className="generate-btn" 
          type="button" 
          onClick={handleGenerateIntelligence}
          disabled={!isGenerateEnabled || isGenerating}
        >
          {isGenerating ? 'GENERATING...' : 'GENERATE INTELLIGENCE'}
        </button>
      </div>
      <div className="column save">
        <button 
          className="save-btn" 
          type="button" 
          onClick={handleSaveLeads}
          disabled={!isSaveEnabled || isSaving}
        >
          {isSaving ? 'SAVING...' : 'SAVE LEADS'}
        </button>
      </div>
    </div>
  );
}

export default InfoActionContainer;