import React from 'react';
import { SignUp } from "@clerk/clerk-react";
import { Box, Typography, Container, Grid } from '@mui/material';
import AIImage from '../brain.png';
import './Login.css';
export function Register() {
  // Define a function to render the SignUp form
  const renderForm = () => (
    <SignUp
      routing="path"
      path="/register"
      afterSignUpUrl="/home"
      signInUrl="/login"
      redirectUrl="/home"
    />
  );
  return (
    <Container maxWidth="xl" sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center' }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="h4" gutterBottom>Unlock the power of AI</Typography>
            <Typography variant="subtitle1" gutterBottom>
              Revolutionize your marketing with Hype AI.
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Seamlessly integrate with various marketing platforms
            </Typography>
            <img src={AIImage} alt="Hype Awareness" style={{ width: 400 }} />
            <Typography variant="subtitle1">
              Revolutionize your marketing with Hype AI.
            </Typography>
            <Typography variant="subtitle1">
              Seamlessly integrate
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" flexDirection="column" alignItems="center">
            {renderForm()}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}