import React, { useState, useEffect } from 'react';
import './CampaignAnalytics.css';
import axios from 'axios';
import ViewLeadManagementPopup from './ViewLeadManagementPopup';
import Sidebar from '../MainPage/Sidebar';

const CampaignAnalytics = () => {
  const [campaign, setCampaign] = useState(() => {
    // Initialize campaign from localStorage if available
    return localStorage.getItem('selectedCampaign') || '';
  });
  const [campaigns, setCampaigns] = useState([]);
  const [campaignDetails, setCampaignDetails] = useState([]);
  const [leads, setLeads] = useState([]);
  const [campaignStatus, setCampaignStatus] = useState('Pending');
  const [selectedLead, setSelectedLead] = useState(null);
  const [showViewPopup, setShowViewPopup] = useState(false);

  useEffect(() => {
    fetchCampaigns();
    fetchcampaigntable();
    fetchLeads(campaign);
  }, [campaign]);

  const fetchCampaigns = async () => {
    try {
      const response = await axios.get('https://hypelead-backend.onrender.com/campaigns');
      setCampaigns(response.data);
      
      // If there's a stored campaign and it's in the fetched list, use it
      const storedCampaign = localStorage.getItem('selectedCampaign');
      if (storedCampaign && response.data.some(camp => camp.campaign_name === storedCampaign)) {
        setCampaign(storedCampaign);
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  const fetchcampaigntable = async () => {
    if (!campaign) return;
    try {
      const response = await axios.get(`https://hypelead-backend.onrender.com/campaign_table/${campaign}`);
      setCampaignDetails(response.data);
      
      // Update campaign status based on lead count and goal
      if (response.data.length > 0) {
        const detail = response.data[0];
        if (detail.count === 0) {
          setCampaignStatus('Pending');
        } else if (detail.count === detail.lead_goals) {
          setCampaignStatus('Completed');
        } else if (detail.count < detail.lead_goals) {
          setCampaignStatus('In-Progress');
        }
      }
    } catch (error) {
      console.error('Error checking generated emails:', error);
    }
  };

  const fetchLeads = async (selectedCampaign) => {
    if (!selectedCampaign) return;
    try {
      const response = await axios.get(`https://hypelead-backend.onrender.com/campaign_leads/${selectedCampaign}`);
      setLeads(response.data);
    } catch (error) {
      console.error('Error fetching leads:', error);
    }
  };

  const handleCampaignChange = (e) => {
    const selectedCampaign = e.target.value;
    setCampaign(selectedCampaign);
    localStorage.setItem('selectedCampaign', selectedCampaign);
    fetchLeads(selectedCampaign);
  };

  const handleView = (lead) => {
    setSelectedLead(lead);
    setShowViewPopup(true);
  };

  const handleCloseViewPopup = () => {
    setShowViewPopup(false);
    setSelectedLead(null);
  };

  const handleDelete = async (email) => {
    if (window.confirm("Are you sure you want to delete this lead?")) {
      try {
        await axios.delete(`https://hypelead-backend.onrender.com/delete_lead/${email}/${campaign}`);
        // Refresh the leads list after deletion
        fetchLeads(campaign);
      } catch (error) {
        console.error('Error deleting lead:', error);
      }
    }
  };

  return (
    <div className="campaign-analytics">
      <Sidebar/>
      <header className="campaign-header">
        <h1>
          Campaigns Analytics{' '}
          <select 
            value={campaign} 
            onChange={handleCampaignChange}
            className="campaign-sel"
          >
            {/* <option value="">Unselected</option> */}
            {campaigns.map((camp) => (
              <option key={camp.campaign_name} value={camp.campaign_name}>
                {camp.campaign_name}
              </option>
            ))}
          </select>
        </h1>
         <div className={`campaign-status ${campaignStatus.toLowerCase()}`}>{campaignStatus}</div>
      </header>

      <section className="campaign-info">
        <div className="campaign-details">
          <h1>{campaign}</h1>
          {/* <p>Created at: {campaignDetails[selectedCampaign].createdDate}</p> */}
        </div>

          {campaignDetails.map((detail, index) => (
            <div key={index}>
              <div className="campaign-stats">
                <div>Campaign Region: <span>{detail.campaign_region}</span></div>
                <div>Campaign Type: <span>{detail.campaign_type}</span></div>
                <div>Lead Uploaded: <span>{detail.count}</span></div>
                <div>Lead Goal: <span>{detail.lead_goals}</span></div>
                <div>Mail Sent: <span>N/A</span></div>
                <div>Mail Delivered: <span>N/A</span></div>
                <div>Mail opened: <span>N/A</span></div>
                <div>Mail Replied: <span>N/A</span></div>
              </div>
            </div>
          ))} 
      </section>

      <section className="campaign-content">
      <div className="leads">
        <div className="leads-header">
          <h1>Leads</h1>
          <button className="add-lead-btn">Add Lead</button>
        </div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Score</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {leads.map((lead, index) => (
              <tr key={lead.first_name}>
                <td>{lead.first_name}</td>
                <td>{lead.score}</td>
                <td className="actions">
                  <button onClick={() => handleView(lead)} className="action-btn view-btn">View</button>
                  <button onClick={() => handleDelete(lead.email)} className="action-button">Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
        <div className="campaign-overview">
          <h1>Campaign Overview</h1>
          {campaignDetails.map((detail, index) => (
          <form key={index}>
            <label>
              Campaign Type:
              <input type="text" value={campaign} readOnly />
            </label>
            <label>
              Campaign Description:
              <input type="text" value={detail.description} readOnly className="large-text"/>
            </label>
            <label>
              Campaign Offer:
              <input type="text" value={detail.campaign_offer} readOnly className="large-text"/>
            </label>
          </form>
          ))}
        </div>
      </section>
      {showViewPopup && selectedLead && (
        <ViewLeadManagementPopup
          lead={selectedLead}
          onClose={handleCloseViewPopup}
        />
      )}
    </div>
  );
};

export default CampaignAnalytics;

