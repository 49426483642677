import React, { useEffect } from 'react';
import { OrganizationProfile, OrganizationSwitcher, useOrganization, useOrganizationList, useUser } from '@clerk/clerk-react';
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const OrganizationTab = () => {
  const { organization, isLoaded: isOrgLoaded } = useOrganization();
  const { userMemberships, isLoaded: isMembershipsLoaded } = useOrganizationList();
  const { user } = useUser();

  useEffect(() => {
    console.log('Organization:', organization);
    console.log('Is Organization Loaded:', isOrgLoaded);
    console.log('User:', user);
    console.log('User Memberships:', userMemberships);
    console.log('Is Memberships Loaded:', isMembershipsLoaded);
  }, [organization, isOrgLoaded, user, userMemberships, isMembershipsLoaded]);

  if (!isOrgLoaded || !isMembershipsLoaded) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <div>
      <StyledCard>
        <StyledCardContent>
          <Typography variant="h6" gutterBottom>Organization Management</Typography>
          {userMemberships.length === 0 ? (
            <>
              <Typography paragraph>
                You are not a member of any organizations. Would you like to create one?
              </Typography>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => user.createOrganization({ name: 'My New Organization' })}
              >
                Create Organization
              </Button>
            </>
          ) : (
            <>
              <Typography paragraph>
                {organization 
                  ? "Current organization:" 
                  : "You are a member of one or more organizations. Please select an organization:"}
              </Typography>
              <OrganizationSwitcher />
            </>
          )}
        </StyledCardContent>
      </StyledCard>

      {organization && (
        <StyledCard>
          <StyledCardContent>
            <OrganizationProfile 
              appearance={{
                elements: {
                  rootBox: {
                    boxShadow: 'none',
                    width: '100%',
                  },
                  card: {
                    border: '1px solid #e0e0e0',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                    borderRadius: '8px',
                  },
                },
              }}
            />
          </StyledCardContent>
        </StyledCard>
      )}
    </div>
  );
};

export default OrganizationTab;