import React from 'react';
import './ActionButtons.css';

function ActionButtons({ handleClearAll }) {
  return (
    <div className="action-row">
      <button className="upload-btn" type="submit">Upload</button>
      <button className="clear-all-btn" type="button" onClick={handleClearAll}>Clear All</button>
    </div>
  );
}

export default ActionButtons;