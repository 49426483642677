import React from 'react';
import './ViewLeadPopup.css';

function ViewLeadPopup({ lead, onClose }) {
  return (
    <div className="popup-over">
      <div className="popup-text">
        
        <h2>Lead Details</h2>
        <div>
        <p><strong>Name:</strong> {lead.first_name}</p>
        <p><strong>Designation:</strong> {lead.designation}</p>
        </div>
        <p><strong>Email:</strong> {lead.email}</p>
        <p><strong>Company Name:</strong> {lead.company_name}</p>
        <p><strong>Website:</strong> {lead.website}</p>
        <p><strong>Ownered By:</strong> {lead.ownered_by}</p>
        <p><strong>Industry Type:</strong> {lead.industry_type}</p>
        <p><strong>User Intelligence:</strong> {lead.profile_text}</p>
        <button onClick={onClose} className="close-btn">Close</button>
      </div>
    </div>
  );
}

export default ViewLeadPopup;

// import React from 'react';
// import './ViewLeadPopup.css';

// function ViewLeadPopup({ lead, onClose }) {
//   return (
//     <div className="popup-over">
//       <div className="popup-text">
//         <h2>Lead Details</h2>
//         <p><strong>Name:</strong> {lead.first_name}</p>
//         <p><strong>Designation:</strong> {lead.designation}</p>
//         <p><strong>Email:</strong> {lead.email}</p>
        
//         {lead.intelligence && (
//           <div>
//             <h3>Intelligence</h3>
//             <p><strong>Data:</strong> {lead.intelligence.data}</p>
//             {/* Add more intelligence fields as needed */}
//           </div>
//         )}

//         {lead.campaign && (
//           <div>
//             <h3>Campaign</h3>
//             <p><strong>Name:</strong> {lead.campaign.name}</p>
//             <p><strong>Score:</strong> {lead.campaign.score}</p>
//             {/* Add more campaign fields as needed */}
//           </div>
//         )}

//         <button onClick={onClose} className="close-btn">Close</button>
//       </div>
//     </div>
//   );
// }

// export default ViewLeadPopup;
