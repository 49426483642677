import React, { useState, useRef } from 'react';
import axios from 'axios';
import UploadArea from './UploadArea';
import ActionButtons from './ActionButtons';
import InfoActionContainer from './InfoActionContainer';
import InfoSection from './InfoSection';
import Sidebar from './Sidebar';
import './LeadImport.css';
// import OtherHeader from '../InitialPage/OtherHeader';
// import OtherHeader from './components/InitialPage/OtherHeader';


function LeadImport() {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [uploadTime, setUploadTime] = useState('');
  const [campaign, setCampaign] = useState('');
  const [isUploaded, setIsUploaded] = useState(false);
  const fileInputRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setMessage('Please select a file');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    
    formData.append('campaign', campaign);

    try {
      const response = await axios.post('https://hypelead-backend.onrender.com/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setMessage(response.data.message);
      setIsUploaded(true);
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('Error uploading file', error);
      setIsUploaded(false);
    }
  };

  const handleClearAll = () => {
    setFile(null);
    setMessage('');
    setUploadTime('');
    setCampaign('');
    setIsUploaded(false);

  };

  return (
    <div className="app">
      {/* <OtherHeader/> */}
      <div className="sidebar">
      <Sidebar/>
        </div>
      <div className="main-content">
        <h1><span className="import-text">Import</span> Leads</h1>
        <form onSubmit={handleSubmit}>
          <UploadArea
            file={file}
            setFile={setFile}
            setMessage={setMessage}
            setUploadTime={setUploadTime}
            fileInputRef={fileInputRef}
          />
          {message && <p className="message">{message}</p>}
          <ActionButtons
            handleClearAll={handleClearAll}
          />
          <InfoActionContainer
            uploadTime={uploadTime}
            file={file}
            campaign={campaign}
            setCampaign={setCampaign}
            isUploaded={isUploaded}
          />
          <InfoSection />
        </form>
        
      </div>
    </div>
  );
}
export default LeadImport;