import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Button } from '@mui/material'; // Import MUI components
import { useClerk, UserButton, useUser } from "@clerk/clerk-react"; // Clerk hooks for user handling
import './Sidebar.css';
import logo from '../HA_Logo.png'; // Adjust the path as needed
import HomeIcon from '../homeb.png';
import SubscriptionsIcon from '../boradb.png';
import UpdateIcon from '../update.png';
import SettingsIcon from '../settings.png';
import LogoutIcon from '../logout.png';
import rightArrow from '../Iconin.png';
import leftArrow from '../iconto.png';
import Divider from '@mui/material/Divider';

const Sidebar = () => { // Removed `user` prop, use Clerk's user hook
  const navigate = useNavigate();
  const location = useLocation();
  const [activePage, setActivePage] = useState(location.pathname);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Sidebar starts open by default
  const { signOut } = useClerk();
  const { user } = useUser(); // Using Clerk to fetch user

  const handleNavigation = (path) => {
    setActivePage(path);
    navigate(path);
    if (window.innerWidth <= 768) {
      setIsSidebarOpen(false); // Close sidebar on mobile after navigation
    }
  };

  const handleSignOut = () => {
    signOut().then(() => {
      localStorage.removeItem('userEmail');
      localStorage.removeItem('token');
      navigate('/login');
    });
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Top menu items
  const topMenuItems = [
    {
      label: 'Home',
      path: '/home',
      icon: <img src={HomeIcon} alt="Home" /> // Use img tag for base64 icons
    },
    {
      label: 'Manage Subscription',
      path: '/manage-subscription',
      icon: <img src={SubscriptionsIcon} alt="Manage Subscription" />
    },
    {
      label: 'Updates & FAQ',
      path: '/updates-faq',
      icon: <img src={UpdateIcon} alt="Updates & FAQ" />
    },
    {
      label: 'Settings',
      path: '/settings',
      icon: <img src={SettingsIcon} alt="Settings" />
    }
  ];

  return (
    <div className={`sidebar-container ${isSidebarOpen ? 'open' : 'collapsed'}`}>
      <div className={`sidebar ${isSidebarOpen ? 'open' : 'collapsed'}`}>
        <div className="logo-container">
          <img src={logo} alt="Company Logo" className="logo" />
        </div>
        <div className="menu-container">
          {/* Top Menu */}
          <div className="top-menu">
            <div className="user-section">
              <div className="user-info">
                <UserButton afterSignOutUrl="/login" />
                {isSidebarOpen && user?.profileImageUrl && ( // Conditionally render based on sidebar state
                  <img 
                    src={user.profileImageUrl} 
                    alt="User Profile" 
                    style={{ width: 50, height: 50, borderRadius: '50%', marginBottom: 10 }} 
                  />
                )}
              
                {isSidebarOpen && ( // Conditionally render based on sidebar state
                  <>
                    <Typography variant="subtitle2">{user?.fullName || 'User Name'}</Typography>
                    <Typography variant="caption" color="textSecondary">
                      {user?.primaryEmailAddress?.emailAddress || 'user@example.com'}
                    </Typography>
                    <Button
                        variant="contained"
                        className="basic-button" // Added class to Button
                        sx={{
                          mx: 2,
                          mb: 2,
                          bgcolor: '#6366F1',
                          '&:hover': { bgcolor: '#4F46E5' }
                        }}
                      >
                        Basic
                   </Button>
                   <Divider className='logoutline' sx={{ borderColor: '#E2E8F0', borderWidth: '1px' }} /> 

                  </>
                )}
              </div>
            </div>
          </div>

          {/* Navigation Menu */}
          <nav>
            <ul className="middle-menu">
              {topMenuItems.map((item) => (
                <li key={item.path}>
                  <button
                    onClick={() => handleNavigation(item.path)}
                    className={`nav-button ${activePage === item.path ? 'active' : ''}`}
                  >
                    {item.icon}
                    {isSidebarOpen && <span>{item.label}</span>}
                  </button>
                </li>
              ))}
             
            </ul>
          </nav>
          {/* Logout */}
          <div className="footer-divider">
          <Divider
            className="custom-divider"
            sx={{
              borderColor: '#E2E8F0',
              borderWidth: '1px',
            }}
          />
        </div>
          <div className="logout-container">
            <button className="logout-button" onClick={handleSignOut}>
              <img src={LogoutIcon} alt="Logout" />
              {isSidebarOpen && <span>Logout</span>}
            </button>
          </div>
        </div>
      </div>

      {/* Sidebar Toggle Button */}
      <button className="sidebar-toggle" onClick={toggleSidebar}>
      {isSidebarOpen ? (
          <img src={leftArrow} alt="Left Arrow" />
        ) : (
          <img src={rightArrow} alt="Right Arrow" />
        )}
      </button>
    </div>
  );
};

export default Sidebar;