import React from 'react';
import './UploadArea.css';

function UploadArea({ file, setFile, setMessage, setUploadTime, fileInputRef }) {
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.type === 'text/csv') {
      setFile(droppedFile);
      setMessage(`File selected: ${droppedFile.name}`);
      setUploadTime(new Date().toLocaleString());
    } else {
      setMessage('Please drop a CSV file');
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'text/csv') {
      setFile(selectedFile);
      setMessage(`File selected: ${selectedFile.name}`);
      setUploadTime(new Date().toLocaleString());
    } else {
      setMessage('Please select a CSV file');
    }
  };

  return (
    <div className="upload-container">
      <h2>Upload files</h2>
      <div 
        className="upload-area"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={handleUploadClick}
      >
        <input 
          type="file" 
          ref={fileInputRef}
          onChange={handleFileChange} 
          accept=".csv"
          style={{ display: 'none' }}
        />
        {file ? (
          <p>{file.name}</p>
        ) : (
          <>
          <span className="fa-solid fa-upload"></span>
            <p>Click to upload CSV</p>
          </>
        )}
      </div>
    </div>
  );
}

export default UploadArea;