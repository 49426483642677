import React, { useState } from 'react';
import { SignIn } from "@clerk/clerk-react";
import { Box, Typography, Button, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import AIImage from '../brain.png';
import './Login.css';
export function Login() {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [isSignIn, setIsSignIn] = useState(true);
  const renderForm = () => {
    return (
      <SignIn
        routing="path"
        path="/login"
        afterSignInUrl="/home"
        signUpUrl="/register"
        redirectUrl="/home"
      />
    );
  };
  return (
    <Container maxWidth="xl" sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center' }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="h4" gutterBottom>Unlock the power of AI</Typography>
            <Typography variant="subtitle1" gutterBottom>
              Revolutionize your marketing with Hype AI.
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
             Seamlessly integrate with various marketing platforms
            </Typography>
            <img src={AIImage} alt="Hype Awareness" style={{ width: 400 }} />
            <Typography variant="subtitle1">
              Revolutionize your marketing with Hype AI.
            </Typography>
            <Typography variant="subtitle1">
            Seamlessly integrate
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" flexDirection="column" alignItems="center">
            {renderForm()}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}