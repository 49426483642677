import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Button } from '@mui/material';
import { useClerk, UserButton, useUser, OrganizationSwitcher, useOrganization } from "@clerk/clerk-react";
import './Sidebar.css';
import logo from '../HA_Logo.png';
import HomeIcon from '../home.png';
import SubscriptionsIcon from '../manage.png';
import UpdateIcon from '../update.png';
import SettingsIcon from '../settings.png';
import LogoutIcon from '../logout.png';
import rightArrow from '../Iconin.png';
import leftArrow from '../iconto.png';
import Divider from '@mui/material/Divider';

const HomeSidebar = ({ collapsed, toggleSidebar, handleNavigation, handleSignOut }) => {
  const location = useLocation();
  const [activePage, setActivePage] = useState(location.pathname);
  const { user } = useUser();
  const { organization } = useOrganization();
  const isSidebarOpen = !collapsed;

  const handleMenuClick = (path) => {
    setActivePage(path);
    handleNavigation(path);
    if (window.innerWidth <= 768) {
      toggleSidebar();
    }
  };

  // Top menu items
  const topMenuItems = [
    {
      label: 'Home',
      path: '/home',
      icon: <img src={HomeIcon} alt="Home" />
    },
    {
      label: 'Manage Subscription',
      path: '/manage-subscription',
      icon: <img src={SubscriptionsIcon} alt="Manage Subscription" />
    },
    {
      label: 'Updates & FAQ',
      path: '/updates-faq',
      icon: <img src={UpdateIcon} alt="Updates & FAQ" />
    },
    {
      label: 'Settings',
      path: '/settings',
      icon: <img src={SettingsIcon} alt="Settings" />
    }
  ];

  return (
    <div className={`sidebar-container ${isSidebarOpen ? 'open' : 'collapsed'}`}>
      <div className={`sidebar ${isSidebarOpen ? 'open' : 'collapsed'}`}>
        <div className="logo-container">
          <img src={logo} alt="Company Logo" className="logo" />
        </div>
        <div className="menu-container">
          {/* User Section */}
          <div className="top-menu">
            <div className="user-section">
              <div className="user-info">
                <UserButton afterSignOutUrl="/login" />
                {isSidebarOpen && user?.profileImageUrl && (
                  <img 
                    src={user.profileImageUrl} 
                    alt="User Profile" 
                    style={{ width: 50, height: 50, borderRadius: '50%', marginBottom: 10 }} 
                  />
                )}
              
                {isSidebarOpen && (
                  <>
                    <Typography variant="subtitle2">{user?.fullName || 'User Name'}</Typography>
                    <Typography variant="caption" color="textSecondary">
                      {user?.primaryEmailAddress?.emailAddress || 'user@example.com'}
                    </Typography>
                    <Button
                      variant="contained"
                      className="basic-button"
                      sx={{
                        mx: 2,
                        mb: 2,
                        bgcolor: '#6366F1',
                        '&:hover': { bgcolor: '#4F46E5' }
                      }}
                    >
                      Basic
                    </Button>

                    {/* Organization Switcher */}
                    <div className="organization-section">
                      <Typography variant="caption" sx={{ display: 'block', mb: 1, fontWeight: 600 }}>
                        Organization
                      </Typography>
                      <OrganizationSwitcher 
                        appearance={{
                          elements: {
                            rootBox: {
                              width: '100%',
                              marginBottom: '16px'
                            },
                            organizationSwitcherTrigger: {
                              padding: '8px',
                              border: '1px solid #E2E8F0',
                              borderRadius: '8px',
                              backgroundColor: '#F8FAFC'
                            }
                          }
                        }}
                      />
                    </div>
                    
                    <Divider className='logoutline' sx={{ borderColor: '#E2E8F0', borderWidth: '1px' }} />
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Navigation Menu */}
          <nav>
            <ul className="middle-menu">
              {topMenuItems.map((item) => (
                <li key={item.path}>
                  <button
                    onClick={() => handleMenuClick(item.path)}
                    className={`nav-button ${activePage === item.path ? 'active' : ''}`}
                  >
                    {item.icon}
                    {isSidebarOpen && <span>{item.label}</span>}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
          <Divider sx={{ borderColor: '#E2E8F0', borderWidth: '1px' }} />

          {/* Logout */}
          <div className="logout-container">
            <button className="logout-button" onClick={handleSignOut}>
              <img src={LogoutIcon} alt="Logout" />
              {isSidebarOpen && <span>Logout</span>}
            </button>
          </div>
        </div>
      </div>

      {/* Sidebar Toggle Button */}
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        {isSidebarOpen ? (
          <img src={leftArrow} alt="Left Arrow" />
        ) : (
          <img src={rightArrow} alt="Right Arrow" />
        )}
      </button>
    </div>
  );
};

export default HomeSidebar;