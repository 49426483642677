import React, { useState } from 'react';
import { UserProfile, UserButton, useUser, useOrganization } from '@clerk/clerk-react';
import { Box, Typography, Button, Tabs, Tab, Card, CardContent, Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import UpdateIcon from '@mui/icons-material/Update';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import BusinessIcon from '@mui/icons-material/Business';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import NotificationsIcon from '@mui/icons-material/Notifications';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import ApiIcon from '@mui/icons-material/Api';
import HypeLogo from '../HA_Logo.png';
import { useClerk } from "@clerk/clerk-react";
import OrganizationTab from './OrganizationTab';


const PageContainer = styled(Box)({
  display: 'flex',
  minHeight: '100vh',
  backgroundColor: '#F0F4FF',
});

const Sidebar = styled(Box)({
  width: 250,
  backgroundColor: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  borderRight: '1px solid #E0E0E0',
});

const MainContent = styled(Box)({
  flexGrow: 1,
  padding: '20px 40px',
});


const NavButton = styled(ListItem)(({ theme, active }) => ({
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '100%',
    padding: '10px 20px',
    backgroundColor: active ? theme.palette.action.selected : 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  }));

const StyledTab = styled(Tab)({
  textTransform: 'none',
  minWidth: 72,
  fontWeight: 'bold',
  marginRight: '24px',
});

const SettingsPage = () => {
  const navigate = useNavigate();
  const { signOut } = useClerk();
  const { user } = useUser();
  const { organization } = useOrganization();
  const [tabValue, setTabValue] = useState(0);

  const handleSignOut = () => {
    signOut().then(() => {
      localStorage.removeItem('userEmail');
      localStorage.removeItem('token');
      navigate('/login');
    });
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderTabContent = () => {
    switch (tabValue) {
      case 0:
        return <UserProfileTab />;
      case 1:
        return <OrganizationTab />;
      case 2:
        return <BillingTab />;
      case 3:
        return <NotificationsTab />;
      default:
        return <UserProfileTab />;
    }
  };

  return (
    <PageContainer>
      <Sidebar>
        <Box sx={{ p: 2 }}>
          <Link to="/home" style={{ textDecoration: 'none' }}>
            <img src={HypeLogo} alt="Hype Awareness" style={{ width: 80, marginBottom: 20 }} />
          </Link>
        </Box>
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
          <UserButton afterSignOutUrl="/login" />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2">{user?.fullName || 'User Name'}</Typography>
            <Typography variant="caption" color="textSecondary">{user?.primaryEmailAddress?.emailAddress || 'user@example.com'}</Typography>
          </Box>
        </Box>
        <Button 
          variant="contained" 
          sx={{ mx: 2, mb: 2, bgcolor: '#6366F1', '&:hover': { bgcolor: '#4F46E5' } }}
          onClick={() => setTabValue(1)} // Switch to Organization tab
        >
          Basic
        </Button>
        <List>
          <NavButton button onClick={() => handleNavigation('/home')}>
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <ListItemText primary="Home" />
          </NavButton>
          <NavButton button onClick={() => handleNavigation('/manage-subscription')}>
            <ListItemIcon><SubscriptionsIcon /></ListItemIcon>
            <ListItemText primary="Manage Subscription" />
          </NavButton>
          <NavButton button onClick={() => handleNavigation('/updates-faq')}>
            <ListItemIcon><UpdateIcon /></ListItemIcon>
            <ListItemText primary="Updates & FAQ" />
          </NavButton>
          <NavButton button active={true} onClick={() => handleNavigation('/settings')}>
            <ListItemIcon><SettingsIcon /></ListItemIcon>
            <ListItemText primary="Settings" />
          </NavButton>
        </List>
        <Box sx={{ mt: 'auto', mx: 2, mb: 2 }}>
          <Button 
            variant="contained" 
            fullWidth 
            startIcon={<LogoutIcon />}
            onClick={handleSignOut}
            sx={{ 
              bgcolor: '#EF4444', 
              '&:hover': { bgcolor: '#DC2626' },
              color: 'white',
            }}
          >
            Logout
          </Button>
        </Box>
      </Sidebar>
      <MainContent>
        <Typography variant="h4" gutterBottom>Your settings</Typography>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          aria-label="settings tabs"
          sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}
        >
          <StyledTab label="My details" />
          <StyledTab label="Organization" />
          <StyledTab label="Billing" />
          <StyledTab label="Notifications" />
        </Tabs>
        {renderTabContent()}
      </MainContent>
    </PageContainer>
  );
};

const UserProfileTab = () => {
  return (
    <UserProfile 
      path="/settings"
      routing="path"
      appearance={{
        elements: {
          rootBox: {
            boxShadow: 'none',
            width: '100%',
          },
          card: {
            border: '1px solid #e0e0e0',
            boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
            borderRadius: '8px',
          },
        },
      }}
    />
  );
};



const BillingTab = () => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>Billing Information</Typography>
        {/* Add billing information and history here */}
      </CardContent>
    </Card>
  );
};

const NotificationsTab = () => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>Notification Preferences</Typography>
        {/* Add notification settings here */}
      </CardContent>
    </Card>
  );
};

const IntegrationsTab = () => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>Integrations</Typography>
        {/* Add integration options here */}
      </CardContent>
    </Card>
  );
};

const ApiTab = () => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>API Settings</Typography>
        {/* Add API settings and keys here */}
      </CardContent>
    </Card>
  );
};

export default SettingsPage;