// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './CampaignsTable.css';
// import CreateCampaignPopup from './CreateCampaignPopup';
// import ViewCampaignPopup from './ViewCampaignpopup';
// import EditCampaignPopup from './EditCampaignPopup';

// function CampaignsTable() {
//   const [campaigns, setCampaigns] = useState([]);
//   const [showCreatePopup, setShowCreatePopup] = useState(false);
//   const [showViewPopup, setShowViewPopup] = useState(false);
//   const [showEditPopup, setShowEditPopup] = useState(false);
//   const [selectedCampaign, setSelectedCampaign] = useState(null);

//   useEffect(() => {
//     fetchCampaigns();
//   }, []);

//   const fetchCampaigns = () => {
//     axios.get('http://localhost:5000/campaignstable')
//       .then(response => setCampaigns(response.data))
//       .catch(error => console.error('Error fetching campaigns:', error));
//   };

//   const handleCreateCampaign = () => {
//     setShowCreatePopup(true);
//   };

//   const handleCloseCreatePopup = () => {
//     setShowCreatePopup(false);
//   };

//   const handleSaveCampaign = (newCampaign) => {
//     setCampaigns([...campaigns, newCampaign]);
//   };

//   const handleView = (campaign) => {
//     setSelectedCampaign(campaign);
//     setShowViewPopup(true);
//   };

//   const handleCloseViewPopup = () => {
//     setShowViewPopup(false);
//     setSelectedCampaign(null);
//   };

//   const handleEdit = (campaign) => {
//     setSelectedCampaign(campaign);
//     setShowEditPopup(true);
//   };

//   const handleCloseEditPopup = () => {
//     setShowEditPopup(false);
//     setSelectedCampaign(null);
//   };

//   const handleSaveEditedCampaign = (updatedCampaign) => {
//     setCampaigns(campaigns.map(c => (c.campaign_name === updatedCampaign.campaign_name ? updatedCampaign : c)));
//   };

//   const handleDelete = (campaignName) => {
//     if (window.confirm('Are you sure you want to delete this campaign?')) {
//       axios.delete(`http://localhost:5000/campaign/${campaignName}`)
//         .then(() => {
//           setCampaigns(prevCampaigns => prevCampaigns.filter(c => c.campaign_name !== campaignName));
//         })
//         .catch(error => console.error('Error deleting campaign:', error));
//     }
//   };

//   return (
//     <div className="campaigns-container">
//       <div className="campaigns-header">
//         <h1>All Campaigns</h1>
//         <button className="create-campaign-btn" onClick={handleCreateCampaign}>CREATE NEW CAMPAIGN</button>
//       </div>
//       <table className="campaigns-table">
//         <thead>
//           <tr>
//             <th>CAMPAIGN NAME</th>
//             <th>TYPE</th>
//             <th>REGIONS</th>
//             <th>LEAD STATUS</th>
//             <th>LEAD GOAL</th>
//             <th>CAMPAIGN STATUS</th>
//             <th>ACTIONS</th>
//           </tr>
//         </thead>
//         <tbody>
//           {campaigns.map(campaign => (
//             <tr key={campaign.campaign_name}>
//               <td>{campaign.campaign_name}</td>
//               <td>{campaign.campaign_type}</td>
//               <td>{campaign.campaign_region}</td>
//               <td>{campaign.uploaded_lead}</td>
//               <td>{campaign.lead_goals}</td>
//               <td>{campaign.camapign_status}</td>
//               <td className="actions-cell">
//                 <button onClick={() => handleView(campaign)} className="action-btn view-btn">
//                   <span className="icon">👁️</span>
//                 </button>
//                 <button onClick={() => handleEdit(campaign)} className="action-btn edit-btn">
//                   <span className="icon">✏️</span>
//                 </button>
//                 <button onClick={() => handleDelete(campaign.campaign_name)} className="action-btn delete-btn">
//                   <span className="icon">🗑️</span>
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       {showCreatePopup && (
//         <CreateCampaignPopup
//           onClose={handleCloseCreatePopup}
//           onSave={handleSaveCampaign}
//         />
//       )}
//       {showViewPopup && selectedCampaign && (
//         <ViewCampaignPopup
//           campaign={selectedCampaign}
//           onClose={handleCloseViewPopup}
//         />
//       )}
//       {showEditPopup && selectedCampaign && (
//         <EditCampaignPopup
//           campaign={selectedCampaign}
//           onClose={handleCloseEditPopup}
//           onSave={handleSaveEditedCampaign}
//         />
//       )}
//     </div>
//   );
// }

// export default CampaignsTable;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CampaignsTable.css';
import CreateCampaignPopup from './CreateCampaignPopup';
import ViewCampaignPopup from './ViewCampaignpopup';
import EditCampaignPopup from './EditCampaignPopup';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Sidebar from '../MainPage/Sidebar';

function CampaignsTable() {
  const [campaigns, setCampaigns] = useState([]);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [showViewPopup, setShowViewPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const fetchCampaigns = () => {
    axios.get('https://hypelead-backend.onrender.com/campaignstable')
      .then(response => setCampaigns(response.data))
      .catch(error => console.error('Error fetching campaigns:', error));
  };

  const handleCreateCampaign = () => {
    setShowCreatePopup(true);
  };

  const handleCloseCreatePopup = () => {
    setShowCreatePopup(false);
  };

  const handleSaveCampaign = (newCampaign) => {
    setCampaigns([...campaigns, newCampaign]);
  };

  const handleView = (campaign) => {
    setSelectedCampaign(campaign);
    setShowViewPopup(true);
  };

  const handleCloseViewPopup = () => {
    setShowViewPopup(false);
    setSelectedCampaign(null);
  };

  const handleEdit = (campaign) => {
    setSelectedCampaign(campaign);
    setShowEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setShowEditPopup(false);
    setSelectedCampaign(null);
  };

  const handleSaveEditedCampaign = (updatedCampaign) => {
    setCampaigns(campaigns.map(c => (c.campaign_name === updatedCampaign.campaign_name ? updatedCampaign : c)));
  };

  const handleDelete = (campaignName) => {
    if (window.confirm('Are you sure you want to delete this campaign?')) {
      axios.delete(`https://hypelead-backend.onrender.com/campaign/${campaignName}`)
        .then(() => {
          setCampaigns(prevCampaigns => prevCampaigns.filter(c => c.campaign_name !== campaignName));
        })
        .catch(error => console.error('Error deleting campaign:', error));
    }
  };

  const getCampaignStatus = (leadGoal, uploadedLead) => {
    // if (uploadedLead === 'null') return {uploadedLead =0};
    // return (uploadedLead);
    console.log(uploadedLead)
    const goal = Number(leadGoal);
    const uploaded = Number(uploadedLead);
    if (uploaded === 0) return { status: 'Pending', color: '#fa2a2a'};
    if (uploaded < goal) return { status: 'In Progress', color: '#239af5' };
    if (uploaded === goal)return { status: 'Completed', color: '#32CD32'};
    return { status: 'Wrong Detail', color: '#f4c60d'};
  };
  return (
    <div className="campaigns-container">
      <Sidebar/>
      <div className="campaigns-header">
      <h1>All Campaigns</h1>
        <button className="create-campaign-btn" onClick={handleCreateCampaign}>CREATE NEW CAMPAIGN</button>
      </div>
      <table className="campaigns-table">
        <thead>
          <tr>
            <th>CAMPAIGN NAME</th>
            <th>TYPE</th>
            <th>REGIONS</th>
            <th>LEAD STATUS</th>
            <th>LEAD GOAL</th>
            <th>CAMPAIGN STATUS</th>
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {campaigns.map(campaign => {
            const { status, color} = getCampaignStatus(campaign.lead_goals, campaign.uploaded_lead);
            return (
              <tr key={campaign.campaign_name}>
                <td>{campaign.campaign_name}</td>
                <td>{campaign.campaign_type}</td>
                <td>{campaign.campaign_region}</td>
                <td>{campaign.uploaded_lead}</td>
                <td>{campaign.lead_goals}</td>
                <td>
                  <button 
                    className="status-btn" 
                    style={{ 
                      backgroundColor: color,
                      color: 'black',
                      border: 'none',
                      padding: '5px 10px',
                      borderRadius: '5px',
                      cursor: 'default'
                    }}
                  >
                    {status}
                  </button>
                </td>
                <td className="actions-cell">
                  <button onClick={() => handleView(campaign)} className="action-btn view-btn">
                    <span className="fas fa-eye"></span>
                  </button>
                  <button onClick={() => handleEdit(campaign)} className="action-btn edit-btn">
                    <span className="fas fa-edit"></span>
                  </button>
                  <button onClick={() => handleDelete(campaign.campaign_name)} className="action-btn delete-btn">
                    <span className="fas fa-trash-alt"></span>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {showCreatePopup && (
        <CreateCampaignPopup
          onClose={handleCloseCreatePopup}
          onSave={handleSaveCampaign}
        />
      )}
      {showViewPopup && selectedCampaign && (
        <ViewCampaignPopup
          campaign={selectedCampaign}
          onClose={handleCloseViewPopup}
        />
      )}
      {showEditPopup && selectedCampaign && (
        <EditCampaignPopup
          campaign={selectedCampaign}
          onClose={handleCloseEditPopup}
          onSave={handleSaveEditedCampaign}
        />
      )}
    </div>
  );
}

export default CampaignsTable;