import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, TextField, Button, Select, MenuItem, IconButton, List, ListItem, ListItemText, ListItemIcon, InputAdornment, Paper, Modal, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import SendIcon from '../sendto.png';
import EditIcon from '@mui/icons-material/Edit';
import { OrganizationSwitcher, useOrganization } from "@clerk/clerk-react";

import ChatIcon from '@mui/icons-material/Chat';
import StarIcon from '@mui/icons-material/Star';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import { Link } from 'react-router-dom';
import HypeLogo from '../HA_Logo.png';
import { useUser, useAuth } from "@clerk/clerk-react";
import rightArrow from '../Iconin.png';
import leftArrow from '../iconto.png';
import haclose from '../closeha.png';
import addChatImage from '../addchat.png';
import Divider from '@mui/material/Divider';
import './AnalyticsPage.css';
import AddPromptModal from './AddPromptModal';
import doc from '../doc.png';


const RightSidebar = styled(Box)(({ theme, open }) => ({
  width: open ? 250 : 60,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  borderLeft: '1px solid #E2E8F0',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F8FAFC',
}));

const AppContainer = styled(Box)({
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
  });
  
  const Sidebar = styled(Box)(({ theme, open, side }) => ({
    width: open ? 250 : 60,
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    overflowY: 'auto',
    borderRight: side === 'left' ? '1px solid #E2E8F0' : 'none',
    borderLeft: side === 'right' ? '1px solid #E2E8F0' : 'none',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F8FAFC',
    position: 'fixed',
    top: 0,
    [side]: 0,
    zIndex: 1,
  }));
  
  
  const MainContent = styled(Box)(({ theme, leftOpen, rightOpen }) => ({
    flexGrow: 1,
    height: '100%',
    transition: theme.transitions.create(['margin-left', 'margin-right'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: leftOpen ? 250 : 60,
    marginRight: rightOpen ? 250 : 60,
    display: 'flex',
    flexDirection: 'column',
  }));

  const ChatHeader = styled(Box)({
    position: 'sticky',
    top: 0,
    backgroundColor: '#F8FAFC',
    zIndex: 1,
    padding: '20px',
    borderBottom: '1px solid #E2E8F0',
  });
  
  const ChatContent = styled(Box)({
    flexGrow: 1,
    overflowY: 'auto',
    padding: '20px',
  });
  
  const QueryBox = styled(Box)({
    position: 'sticky',
    bottom: 0,
    backgroundColor: '#F8FAFC',
    padding: '20px',
    borderTop: '1px solid #E2E8F0',
  });
  
const StyledButton = styled(Button)({
  borderRadius: 8,
  textTransform: 'none',
  padding: '8px 16px',
  fontSize: '14px',
  fontWeight: 600,
});

const StyledSelect = styled(Select)({
  backgroundColor: '#fff',
  borderRadius: 8,
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});

const ChatListItem = styled(ListItem)({
  borderRadius: 8,
  marginBottom: 4,
  padding: '8px 12px',
  '&:hover': {
    backgroundColor: '#F1F5F9',
  },
});

const MessageBubble = styled(Paper)(({ isUser }) => ({
  padding: '10px 15px',
  borderRadius: 20,
  maxWidth: '70%',
  marginBottom: 10,
  backgroundColor: isUser ? '#E3F2FD' : '#F5F5F5',
  alignSelf: isUser ? '' : 'flex-start',
}));

const ToggleContainer = styled(Box)({
  display: 'flex',
  borderRadius: 20,
  overflow: 'hidden',
  border: '1px solid #E2E8F0',
  marginBottom: 16,
});

const ToggleButton = styled(Button)(({ active }) => ({
  flex: 1,
  padding: '8px 16px',
  backgroundColor: active ? '#6366F1' : 'transparent',
  color: active ? '#fff' : '#64748B',
  '&:hover': {
    backgroundColor: active ? '#4F46E5' : '#F1F5F9',
  },
}));

const PromptModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const PromptModalContent = styled(Box)({
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '8px',
  width: '400px',
});

const PromptChip = styled(Chip)({
  margin: '4px',
  cursor: 'pointer',
});

const AnalyticsPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [query, setQuery] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [recentChats, setRecentChats] = useState([]);
  const [currentChatName, setCurrentChatName] = useState('Google Analytics Report');
  const [isEditingChatName, setIsEditingChatName] = useState(false);
  const [activeTab, setActiveTab] = useState('analytics');
  const [userEmail, setUserEmail] = useState('');
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(true);
  const [rightSidebarOpen, setRightSidebarOpen] = useState(true);
  const [customPrompts, setCustomPrompts] = useState([]);
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
  const [newPrompt, setNewPrompt] = useState('');
  const [conversations, setConversations] = useState([]);
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const { isLoaded, isSignedIn, user } = useUser();
  const [editedChatName, setEditedChatName] = useState('');
  const [prompts, setPrompts] = useState([]);
  const [promptGroups, setPromptGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [newPromptContent, setNewPromptContent] = useState('');
  const [editingPromptId, setEditingPromptId] = useState(null);
  const [newGroupName, setNewGroupName] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  
  const { getToken } = useAuth();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
  const api = axios.create({
    baseURL: BACKEND_URL
  });
  
  
  useEffect(() => {
    fetchClients();
    fetchRecentChats();
    fetchUserEmail();
    const email = localStorage.getItem('userEmail');
    setUserEmail(email);
  }, []);

  useEffect(() => {
    fetchConversations();
  }, []);

  const fetchClients = async (retryCount = 0) => {
    try {
      setLoading(true);
      const token = await getToken();
      if (!token && retryCount < 3) {
        setTimeout(() => fetchClients(retryCount + 1), 1000);
        return;
      }
      if (!token) {
        throw new Error("Failed to get authentication token");
      }
      const response = await axios.get(`${BACKEND_URL}/api/clients`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      setClients(response.data.map(db => ({ id: db, name: db })));
    } catch (error) {
      console.error('Error fetching clients:', error);
      setError(error.message || 'An error occurred while fetching clients');
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchClients();
  }, []);

  const handleClientChange = (event) => {
    setSelectedClient(event.target.value);
  };


  const fetchConversations = async () => {
    try {
      const token = await getToken();
      const response = await axios.get(`${BACKEND_URL}/api/get_conversations`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      setConversations(response.data);
    } catch (error) {
      console.error('Error fetching conversations:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
    }
  };

  const saveConversation = async () => {
    if (chatHistory.length === 0) {
      console.log('No chat history to save');
      return;
    }
  
    try {
      const token = await getToken();
      console.log('Saving conversation:', {
        conversation_name: currentChatName,
        chat_history: chatHistory
      });
      const response = await axios.post(`${BACKEND_URL}/api/save_conversation`, {
        conversation_name: currentChatName,
        chat_history: chatHistory
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      console.log('Save conversation response:', response.data);
      setCurrentConversationId(response.data.conversation_id);
      fetchConversations();  // Refresh the list of conversations
    } catch (error) {
      console.error('Error saving conversation:', error.response?.data || error.message);
    }
  };
  const fetchRecentChats = async () => {
    setRecentChats([
      { id: 1, name: 'Google Analytics Report' },
      { id: 2, name: 'Conversion Funnel Analysis' },
      { id: 3, name: 'User Engagement Metrics' },
    ]);
  };

  const fetchUserEmail = async () => {
    setUserEmail('user@example.com');
  };


  const handleQuerySubmit = async () => {
    if (!query.trim()) return;
  
    const newMessage = { role: 'user', content: query };
    setChatHistory([...chatHistory, newMessage]);
    setQuery('');
  
    try {
      const token = await getToken();
      if (!token) {
        throw new Error("Failed to get authentication token");
      }
  
      const response = await axios.post(`${BACKEND_URL}/api/query`, {
        client: selectedClient,
        query: query
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
  
      const aiMessage = { role: 'ai', content: response.data.result };
      setChatHistory(prevHistory => [...prevHistory, aiMessage]);
      await saveConversation();
    } catch (error) {
      console.error('Error submitting query:', error);
      let errorMessage = 'An error occurred while processing your request.';
      if (error.response) {
        console.error('Error response:', error.response.data);
        errorMessage = error.response.data.error || error.response.data.message || errorMessage;
        if (error.response.data.traceback) {
          console.error('Error traceback:', error.response.data.traceback);
        }
      } else if (error.request) {
        errorMessage = 'No response received from server.';
      } else {
        errorMessage = error.message;
      }
      setChatHistory(prevHistory => [...prevHistory, { role: 'ai', content: `Error: ${errorMessage}` }]);
    }

  };

  const handleCreateNewChat = () => {
    const newChatName = `New Chat ${conversations.length + 1}`;
    setCurrentChatName(newChatName);
    setChatHistory([]);
    setCurrentConversationId(null);
  };


  const handleEditChatName = () => {
    setEditedChatName(currentChatName);
    setIsEditingChatName(true);
  };
  
  const handleCancelEdit = () => {
    setIsEditingChatName(false);
    setEditedChatName('');
  };

  const handleSaveChatName = async () => {
    if (editedChatName.trim() === '') return;
    
    try {
      const token = await getToken();
      const response = await axios.put(`${BACKEND_URL}/api/update_conversation_name`, {
        conversation_id: currentConversationId,
        new_name: editedChatName.trim()
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (response.data.success) {
        setCurrentChatName(editedChatName.trim());
        setIsEditingChatName(false);
        fetchConversations();  // Refresh the list of conversations
      } else {
        console.error('Failed to update conversation name:', response.data.error);
      }
    } catch (error) {
      console.error('Error updating conversation name:', error);
    }
  };

  const handleChatNameChange = (event) => {
    setCurrentChatName(event.target.value);
  };

  const handleChatNameSubmit = () => {
    setIsEditingChatName(false);
    setRecentChats(recentChats.map(chat => 
      chat.name === currentChatName ? { ...chat, name: currentChatName } : chat
    ));
  };

  const handleChatSelect = (conversationId) => {
    const selectedConversation = conversations.find(conv => conv.id === conversationId);
    if (selectedConversation) {
      setCurrentChatName(selectedConversation.name);
      setChatHistory(selectedConversation.chat_history);
      setCurrentConversationId(conversationId);
    }
  };


  const toggleLeftSidebar = () => {
    setLeftSidebarOpen(!leftSidebarOpen);
  };

  const toggleRightSidebar = () => {
    setRightSidebarOpen(!rightSidebarOpen);
  };

  const handleSelectPrompt = (prompt) => {
    setQuery(prompt);
  };

  const handleCreateNewClient = () => {
    navigate('/datasource-connector');
  };


  useEffect(() => {
    fetchPrompts();
    fetchPromptGroups();
  }, []);

  const fetchPrompts = async () => {
    try {
      const token = await getToken();
      const response = await axios.get(`${BACKEND_URL}/api/prompts`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setPrompts(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching prompts:', error);
      setError('Failed to fetch prompts. Please try again later.');
      setPrompts([]);
    }
  };



  const fetchPromptGroups = async () => {
    try {
      const token = await getToken();
      const response = await axios.get(`${BACKEND_URL}/api/prompt_groups`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setPromptGroups(response.data);
      console.log('Fetched prompt groups:', response.data);
    } catch (error) {
      console.error('Error fetching prompt groups:', error);
      setError('Failed to fetch prompt groups. Please try again later.');
    }
  };
  

  const handleAddPrompt = async () => {
    if (!selectedGroup || !newPromptContent.trim()) return;
    
    try {
      const token = await getToken();
      const response = await axios.post(`${BACKEND_URL}/api/prompts`, {
        group_name: selectedGroup,
        content: newPromptContent.trim()
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setPrompts(prevPrompts => [...prevPrompts, response.data]);
      setNewPromptContent('');
      setError(null);
      console.log('Added new prompt:', response.data); // Add this log
    } catch (error) {
      console.error('Error adding prompt:', error);
      setError('Failed to add prompt. Please try again.');
    }
  };



  const handleUpdatePrompt = async (promptId) => {
    const promptToUpdate = prompts.find(p => p.id === promptId);
    if (!promptToUpdate) return;

    try {
      const token = await getToken();
      const response = await axios.put(`${BACKEND_URL}/api/prompts/${promptId}`, {
        content: promptToUpdate.content
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setPrompts(prompts.map(p => p.id === promptId ? response.data : p));
      setEditingPromptId(null);
    } catch (error) {
      console.error('Error updating prompt:', error);
    }
  };

  const handleDeletePrompt = async (promptId) => {
    try {
      const token = await getToken();
      await axios.delete(`${BACKEND_URL}/api/prompts/${promptId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setPrompts(prompts.filter(p => p.id !== promptId));
    } catch (error) {
      console.error('Error deleting prompt:', error);
    }
  };

  const handleCreateNewGroup = async () => {
    if (!newGroupName.trim() || promptGroups.includes(newGroupName.trim())) return;
    
    try {
      const token = await getToken();
      await axios.post(`${BACKEND_URL}/api/prompt_groups`, {
        group_name: newGroupName.trim()
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setPromptGroups(prevGroups => [...prevGroups, newGroupName.trim()]);
      setSelectedGroup(newGroupName.trim());
      setNewGroupName('');
      console.log('Created new group:', newGroupName.trim());
    } catch (error) {
      console.error('Error creating new group:', error);
      setError('Failed to create new group. Please try again.');
    }
  };

  const groupedPrompts = prompts.reduce((acc, prompt) => {
    if (!acc[prompt.group_name]) {
      acc[prompt.group_name] = [];
    }
    acc[prompt.group_name].push(prompt);
    return acc;
  }, {});



  useEffect(() => {
    if (selectedGroup && !groupedPrompts[selectedGroup]) {
      console.log('Selected group not found in groupedPrompts:', selectedGroup);
      // Instead of clearing the selection, you might want to keep it
      // setSelectedGroup('');
    }
  }, [groupedPrompts, selectedGroup]);
  

  return (
    <AppContainer>
<Sidebar open={leftSidebarOpen} side="left" className="left-sidebar">
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 2 }}>
    {leftSidebarOpen && (
      <Link to="/home" style={{ textDecoration: 'none' }}>
        <img src={HypeLogo} alt="Hype Awareness" style={{ width: 80, marginBottom: 20 }} />
      </Link>
    )}
    <IconButton 
      className="toggle-sidebar-button" 
      onClick={() => setLeftSidebarOpen(!leftSidebarOpen)}
    >
      {leftSidebarOpen ? (
        <img src={leftArrow} alt="Left Arrow" className="left-arrow-img" />
      ) : (
        <img src={rightArrow} alt="Right Arrow" className="right-arrow-img" />
      )}
    </IconButton>
  </Box>
  {leftSidebarOpen && (
    <>
      {/* User and Organization Section */}
      <Box sx={{ padding: '20px', borderBottom: '1px solid #E2E8F0' }}>

        <Box sx={{ mb: 2 }}>
          <Typography variant="caption" sx={{ fontWeight: 700, display: 'block', mb: 1 }}>
            Organization
          </Typography>
          <OrganizationSwitcher 
            appearance={{
              elements: {
                rootBox: {
                  width: '100%',
                },
                organizationSwitcherTrigger: {
                  padding: '8px',
                  border: '1px solid #E2E8F0',
                  borderRadius: '8px',
                  backgroundColor: '#F8FAFC'
                }
              }
            }}
          />
        </Box>
      </Box>

      {/* Rest of your existing sidebar content */}
      <ToggleContainer className="toggle-container">
        <ToggleButton
          className="toggle-button-a"
          active={activeTab === 'analytics'}
          onClick={() => setActiveTab('analytics')}
        >
          Analytics
        </ToggleButton>
        <ToggleButton
          className="toggle-button-l"
          active={activeTab === 'leads'}
          onClick={() => setActiveTab('leads')}
        >
          Leads
        </ToggleButton>
      </ToggleContainer>
      <Divider sx={{ borderColor: '#E2E8F0', borderWidth: '1px' }} />
      <StyledButton
        className="create-new-client-button"
        variant="contained"
        fullWidth
        sx={{
          margin: 2,
          backgroundColor: '#6366F1',
          '&:hover': { backgroundColor: '#4F46E5' },
        }}
        onClick={handleCreateNewClient}
      >
        Create New Client
      </StyledButton>
            <p className='Select Client'>Select Client</p>      
            <StyledSelect
              value={selectedClient}
              onChange={handleClientChange}
              fullWidth
              displayEmpty
              sx={{ margin: 2 }}
            >
              <MenuItem value="" disabled>Select Client</MenuItem>
              {clients.map((client) => (
                <MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>
              ))}
            </StyledSelect>
            <Divider sx={{ borderColor: '#E2E8F0', borderWidth: '1px' }} />
            <Typography variant="subtitle2" sx={{ fontWeight: 600, margin: 2 }}>Recent Chats</Typography>
            <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
              {conversations.map((conv) => (
               <ChatListItem
                    key={conv.id}
                    className="chat-list-item" 
                    selected={conv.id === currentConversationId}
                    onClick={() => handleChatSelect(conv.id)}
                  >
                    <ListItemIcon className="list-item-icon">  {/* Added class to ListItemIcon */}
                      <ChatIcon fontSize="small" className="chat-icon" /> {/* Added class to ChatIcon */}
                    </ListItemIcon>
                    <ListItemText
                      primary={conv.name}
                      secondary={new Date(conv.updated_at).toLocaleString()}
                      className="list-item-text" 
                      primaryTypographyProps={{ fontSize: '14px' }}
                    />
             </ChatListItem>
             
              ))}
            </List>
            <StyledButton
              className="start-new-chat-button"
              startIcon={<ChatIcon />}
              variant="contained"
              fullWidth
              sx={{
                margin: 2,
                backgroundColor: '#6366F1',
                '&:hover': { backgroundColor: '#4F46E5' },
              }}
              onClick={handleCreateNewChat}
            >
              Start New Chat
          </StyledButton>

            <Box sx={{ mt: 'auto', p: 2, borderTop: '1px solid #E2E8F0' }}>
                <Typography variant="body2">{userEmail}</Typography>
            </Box>
          </>
        )}
      </Sidebar>
      
      <MainContent leftOpen={leftSidebarOpen} rightOpen={rightSidebarOpen}>
        <ChatHeader className="chat-header">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isEditingChatName ? (
              <>
                <TextField
                  value={editedChatName}
                  onChange={(e) => setEditedChatName(e.target.value)}
                  autoFocus
                  fullWidth
                  variant="standard"
                />
                <IconButton onClick={handleSaveChatName} size="small">
                  <SaveIcon />
                </IconButton>
                <IconButton onClick={handleCancelEdit} size="small">
                  <CancelIcon />
                </IconButton>
              </>
            ) : (
              <>
                <Typography variant="h5" fontWeight="bold" sx={{ flexGrow: 1 }}>
                  {currentChatName}
                </Typography>
                <IconButton onClick={handleEditChatName} size="small">
                  <EditIcon />
                </IconButton>
              </>
            )}
          </Box>
        </ChatHeader> 
          <ChatContent className="chat-content">
            {chatHistory.map((message, index) => (
              <MessageBubble 
                key={index} 
                isUser={message.role === 'user'}
                className={`message-bubble ${message.role === 'user' ? 'user-message' : 'bot-message'}`}
              >
                <Typography 
                  variant="body2" 
                  dangerouslySetInnerHTML={{ __html: message.content }} 
                  className="message-text"
                />
              </MessageBubble>
            ))}
          </ChatContent>

          
          <QueryBox className="query-box">
          <TextField
            className="query-input"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleQuerySubmit()}
            placeholder="Reply to HYPE AWARENESS"
            fullWidth
            variant="outlined"
            sx={{ backgroundColor: '#fff', borderRadius: 2 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className="input-end-adornment">
                  <IconButton onClick={handleQuerySubmit} className="send-button">
                    <img src={SendIcon} alt="Send" className="send-icon" style={{ width: "24px", height: "24px" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </QueryBox>
      </MainContent>

      <Sidebar open={rightSidebarOpen} side="right">
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 2 }}>
    {rightSidebarOpen && <Typography variant="h6" className="prompt-library-title">Prompt Library</Typography>}
    <IconButton onClick={() => setRightSidebarOpen(!rightSidebarOpen)}>
      {rightSidebarOpen ? <img src={haclose} alt="Right Arrow" /> : <img src={leftArrow} alt="Left Arrow" />}
    </IconButton>
  </Box>
  {rightSidebarOpen && (
    <Box sx={{ padding: 2 }}>
      {error && (
        <Typography color="error" sx={{ marginBottom: 2 }}>
          {error}
        </Typography>
      )}
      <p className="Select Client">Select Category</p>

      <Select
        value={selectedGroup}
        onChange={(e) => {
          console.log('Selected group changed to:', e.target.value);
          setSelectedGroup(e.target.value);
        }}
        fullWidth
        displayEmpty
        sx={{ marginBottom: 2 }}
      >
        <MenuItem value="" disabled>Select Category</MenuItem>
        {promptGroups.map((group) => (
          <MenuItem key={group} value={group}>{group}</MenuItem>
        ))}
      </Select>

      <Box sx={{ display: 'flex', marginBottom: 2 }}>
        <TextField
          value={newGroupName}
          onChange={(e) => setNewGroupName(e.target.value)}
          placeholder="New group name"
          size="small"
          sx={{ flexGrow: 1, marginRight: 1 }}
        />
        <Button
          variant="contained"
          onClick={handleCreateNewGroup}
          disabled={!newGroupName.trim() || promptGroups.includes(newGroupName.trim())}
        >
          Create
        </Button>
      </Box>
      
      <AddPromptModal
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        newPromptContent={newPromptContent}
        setNewPromptContent={setNewPromptContent}
        handleAddPrompt={handleAddPrompt}
      />

      {Object.entries(groupedPrompts)
        .filter(([group]) => group === selectedGroup) // Only show prompts for selected group  // Filter based on selectedGroup
        .map(([group, groupPrompts]) => (
          <Box key={group} sx={{ marginBottom: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{group}</Typography>
            <List>
              {groupPrompts.map((prompt) => (
                <ListItem key={prompt.id} sx={{ padding: '4px 0' }}>
                  {editingPromptId === prompt.id ? (
                    <>
                      <TextField
                        value={prompt.content}
                        onChange={(e) => setPrompts(prevPrompts => 
                          prevPrompts.map(p => p.id === prompt.id ? {...p, content: e.target.value} : p)
                        )}
                        fullWidth
                        multiline
                        size="small"
                      />
                      <IconButton onClick={() => handleUpdatePrompt(prompt.id)} size="small">
                        <SaveIcon fontSize="small" />
                      </IconButton>
                      <IconButton onClick={() => setEditingPromptId(null)} size="small">
                        <CancelIcon fontSize="small" />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <ListItemText 
                        primary={prompt.content}
                        primaryTypographyProps={{ noWrap: true }}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleSelectPrompt(prompt.content)}
                      />
                      <IconButton onClick={() => setEditingPromptId(prompt.id)} size="small">
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton onClick={() => handleDeletePrompt(prompt.id)} size="small">
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </>
                  )}
                </ListItem>
              ))}
            </List>
          </Box>
      ))}
      <Button
          variant="contained"
          fullWidth
          className="add-prompt-button"
          startIcon={<img src={doc} alt="doc" />}
          onClick={() => setIsPopupOpen(true)}
          sx={{ marginBottom: 2 }}
        >
          Add Your Own Prompt
      </Button>
    </Box>
  )}
</Sidebar>

    </AppContainer>
  );
};

export default AnalyticsPage;