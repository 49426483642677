// import React, { useState } from 'react';
// import axios from 'axios';
// import './CreateCampaignPopup.css';

// function CreateCampaignPopup({ onClose, onSave }) {
//   const [campaignData, setCampaignData] = useState({
//     name: '',
//     type: '',
//     description: '',
//     regions: '',
//     offer: '',
//     leadGoals: '', 
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setCampaignData(prevData => ({
//       ...prevData,
//       [name]: value
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('http://localhost:5000/create_campaign', campaignData);
//       onSave(response.data);
//       onClose();
//     } catch (error) {
//       console.error('Error creating campaign:', error);
//       // Optionally, show an error message to the user
//     }
//   };

//   return (
//     <div className="popup-overlay">
//       <div className="popup-content">
//         <h2>Create Campaign</h2>
//         <form onSubmit={handleSubmit}>
//           <div className="form-group">
//             <label htmlFor="name">Name</label>
//             <input
//               type="text"
//               id="name"
//               name="name"
//               value={campaignData.name}
//               onChange={handleChange}
//               placeholder="unique campaign name"
//               required
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="type">Type</label>
//             <select
//               id="type"
//               name="type"
//               value={campaignData.type}
//               onChange={handleChange}
//               required
//             >
//               <option value="">Select Campaign type</option>
//               <option value="Email">Email</option>
//               <option value="SMS">SEO</option>
//               <option value="Social Media">Social Media</option>
//             </select>
//           </div>
//           <div className="form-group">
//             <label htmlFor="description">Description</label>
//             <textarea
//               id="description"
//               name="description"
//               value={campaignData.description}
//               onChange={handleChange}
//               placeholder="Provide campaign description"
//             ></textarea>
//           </div>
//           <div className="form-group">
//             <label htmlFor="regions">Regions</label>
//             <input
//               type="text"
//               id="regions"
//               name="regions"
//               value={campaignData.regions}
//               onChange={handleChange}
//               placeholder="Provide targeted regions for campaign"
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="offer">Campaign Offer</label>
//             <textarea
//               id="offer"
//               name="offer"
//               value={campaignData.offer}
//               onChange={handleChange}
//               placeholder="Provide the campaign offers"
//             ></textarea>
//           </div>
//           <div className="form-group">
//             <label htmlFor="leadGoals">Lead Goals</label>
//             <input
//               type="number"
//               id="leadGoals"
//               name="leadGoals"
//               value={campaignData.leadGoals}
//               onChange={handleChange}
//               placeholder="Enter the lead goal"
//               min="0"
//             />
//           </div>
//           <div className="form-actions">
//             <button type="submit" className="save-btn">SAVE CAMPAIGN</button>
//             <button type="button" onClick={onClose} className="cancel-btn">Cancel</button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// }

// export default CreateCampaignPopup;

import React, { useState } from 'react';
import axios from 'axios';
import './CreateCampaignPopup.css';

function CreateCampaignPopup({ onClose, onSave }) {
  const [isGenerating, setIsGenerating] = useState(false);
  const [campaignData, setCampaignData] = useState({
    name: '',
    type: '',
    description: '',
    regions: '',
    offer: '',
    leadGoals: '', 
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCampaignData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsGenerating(true);
    try {
      const response = await axios.post('https://hypelead-backend.onrender.com/create_campaign', campaignData);
      onSave(response.data);
      onClose();
    } catch (error) {
      console.error('Error creating campaign:', error);
      // Optionally, show an error message to the user
    }finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Create Campaign</h2>
        <form>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={campaignData.name}
              onChange={handleChange}
              placeholder="unique campaign name"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="type">Type</label>
            <select
              id="type"
              name="type"
              value={campaignData.type}
              onChange={handleChange}
              required
            >
              <option value="">Select Campaign type</option>
              <option value="Email">Email</option>
              <option value="SMS">SEO</option>
              <option value="Social Media">Social Media</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              value={campaignData.description}
              onChange={handleChange}
              placeholder="Provide campaign description"
            ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="regions">Regions</label>
            <input
              type="text"
              id="regions"
              name="regions"
              value={campaignData.regions}
              onChange={handleChange}
              placeholder="Provide targeted regions for campaign"
            />
          </div>
          <div className="form-group">
            <label htmlFor="offer">Campaign Offer</label>
            <textarea
              id="offer"
              name="offer"
              value={campaignData.offer}
              onChange={handleChange}
              placeholder="Provide the campaign offers"
            ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="leadGoals">Lead Goals</label>
            <input
              type="number"
              id="leadGoals"
              name="leadGoals"
              value={campaignData.leadGoals}
              onChange={handleChange}
              placeholder="Enter the lead goal"
              min="0"
            />
          </div>
          <div className="form-actions">
            <button type="submit" className="save-btn" onClick={handleSubmit} disabled={isGenerating}>
            {isGenerating ? 'SAVING...' : 'SAVE CAMPAIGN'}
            </button>
            <button type="button" onClick={onClose} className="cancel-btn">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateCampaignPopup;
