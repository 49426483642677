// // SwitcherButton.js
// import React, { useState } from 'react';
// import './SwitcherButton.css'; // Ensure this path is correct

// const SwitcherButton = () => {
//   const [isAnalyticsActive, setIsAnalyticsActive] = useState(false); // Set default to false for "Leads"

//   const handleToggle = () => {
//     if (!isAnalyticsActive) {
//       // Redirect to the Analytics URL when toggling to "Analytics"
//       window.location.href = 'https://test3.webgeekinfo.com/';
//     }
//     setIsAnalyticsActive(!isAnalyticsActive);
//   };

//   return (
//     <div className="switcher-container">
//       <div className="switcher">
//         <input
//           type="checkbox"
//           id="toggle-switch"
//           checked={isAnalyticsActive}
//           onChange={handleToggle}
//         />
//         <label htmlFor="toggle-switch" className="switcher-label">
//           <span className={`switcher-option ${isAnalyticsActive ? '' : 'active'}`}>
//             Leads
//           </span>
//           <span className={`switcher-option ${isAnalyticsActive ? 'active' : ''}`}>
//             Analytics
//           </span>
//         </label>
//       </div>
//     </div>
//   );
// };

// export default SwitcherButton;


import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const ToggleContainer = styled.div`
  display: inline-flex;
  background-color: #000000;
  border-radius: 30px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 30px;
`;

const ToggleButton = styled.button`
  flex: 1;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  background-color: ${props => props.active ? '#8b5cf6' : '#000000'};
  color: ${props => props.active ? 'white' : 'white'};
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  text-align: center;
`;


const SwitchButton = () => {
  const [isLead, setIsLead] = useState(true);
  const navigate = useNavigate();

  const handleToggle = (value) => {
    setIsLead(value);
    if (value) {
      window.location.href = 'http://localhost:3000/main';
    } else {
      window.location.href = 'https://test3.webgeekinfo.com/';
    }
  };

  return (
    <ToggleContainer>
      <ToggleButton 
        active={isLead} 
        onClick={() => handleToggle(true)}
      >
        Leads
      </ToggleButton>
      <ToggleButton 
        active={!isLead} 
        onClick={() => handleToggle(false)}
      >
        Analytics
      </ToggleButton>
    </ToggleContainer>
  );
};

export default SwitchButton;
