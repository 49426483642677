import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './EditLeadPopup.css';

function EditLeadPopup({ lead, onClose, onSave }) {
  const [leadDetails, setleadDetails] = useState(lead);

  useEffect(() => {
    setleadDetails(lead);
  }, [lead]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setleadDetails(prevState => ({
      ...prevState, 
      [name]: value
    }));
  };

  const handleSave = () => {
    axios.put(`https://hypelead-backend.onrender.com/leadstable/${leadDetails.lead_name}`, leadDetails)
      .then(response => {
        onSave(response.data);
        onClose();
      })
      .catch(error => console.error('Error updating lead:', error));
  };

  return (
    <div className="edit-popup">
      <div className="edit-popup-content">
        <h2>Edit lead</h2>
        <form>
          <label>
            lead Name:
            <input type="text" name="lead_name" value={leadDetails.lead_name} onChange={handleChange} />
          </label>
          <label>
            Designation:
            <input type="text" name="lead_designation" value={leadDetails.lead_designation} onChange={handleChange} />
          </label>
          <label>
            Email:
            <input type="text" name="lead_email" value={leadDetails.lead_email} onChange={handleChange} />
          </label>
          <button type="button" onClick={handleSave}>Save</button>
          <button type="button" onClick={onClose}>Cancel</button>
        </form>
      </div>
    </div>
  );
}

export default EditLeadPopup;
